import { memo, FC, useCallback } from 'react';
import cn from 'classnames';
import { Box } from 'uikit/Box';
import { Theme } from 'uikit/common/types';
import { PagerProps } from './types';
import classes from './Pager.module.scss';
import { PagerBtn } from './PagerBtn';

export const Pager: FC<PagerProps> = memo(({
  total, theme = Theme.dark, index, onChange,
}) => {
  const handlePrevious = useCallback(() => {
    if (index > 0) {
      onChange(index - 1);
    }
  }, [onChange, index]);

  const handleNext = useCallback(() => {
    if (index < total - 1) {
      onChange(index + 1);
    }
  }, [onChange, index, total]);

  return (
    <Box className={cn(classes.wrap, classes[theme])}>
      <PagerBtn
        onClick={handlePrevious}
        disabled={index === 0}
        icon="arrow_left"
        theme={theme}
      />
      <span className={classes.values}>{(index || 0) + 1}/{total}</span>
      <PagerBtn
        onClick={handleNext}
        disabled={index === total - 1}
        icon="arrow_right"
        theme={theme}
      />
    </Box>
  );
});