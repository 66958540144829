import { memo, FC } from 'react';
import cn from 'classnames';
import { Box } from 'uikit/Box';
import { Theme } from 'uikit/common/types';
import { StepProps } from './types';
import { Counter } from './Counter';
import classes from './Step.module.scss';

export const Step: FC<StepProps> = memo(({
  title, count, status, theme = Theme.dark,
}) => {
  return (
    <Box alignItems="center" className={cn(classes.wrap, classes[theme], classes[status])}>
      <Counter count={count} status={status} theme={theme} />
      <Box>{title}</Box>
    </Box>
  );
});