import {
  useCallback, useMemo, memo, FC,
} from 'react';
import { useField } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { Dropdown } from 'uikit/Dropdown';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { getItems } from './helpers';
import { SortingProps } from './types';

export const Sorting: FC<SortingProps> = memo(({
  name, onSubmit, className, interval = 100, page,
}) => {
  const theme = useAppSelector(themeSelector);
  const [, { value }, { setValue }] = useField(name);
  const items = useMemo(() => getItems(page), [page]);
  const debouncedSubmit = useDebouncedCallback(onSubmit, interval);
  const onSelect = useCallback((newValue) => {
    setValue(newValue);
    debouncedSubmit();
  }, [debouncedSubmit, setValue]);
  return (
    <Dropdown
      items={items}
      active={value}
      onSelect={onSelect}
      className={className}
      theme={theme}
      dataTestId="dropdown-sorting"
    />
  );
});