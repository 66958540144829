import { Theme } from 'uikit/common/types';

export enum Status {
    QUEUE = 'QUEUE',
    PROGRESS = 'PROGRESS',
    DONE = 'DONE',
    ERROR = 'ERROR',
    QUEUE_HIDE = 'QUEUE_HIDE',
    DONE_HIDE = 'DONE_HIDE',
}

export interface ProcessStatusProps {
    status: Status;
    className?: string;
    theme?: Theme;
}
