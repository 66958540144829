import {
  memo, FC, useState, useCallback,
} from 'react';
import cn from 'classnames';
import { Theme } from 'uikit/common/types';
import { Box } from '../Box';
import { AccordionToggle } from './AccordionToggle';
import { AccordionProps } from './types';
import classes from './Accordion.module.scss';

export const Accordion: FC<AccordionProps> = memo(({
  children,
  defaultOpen = false,
  icon,
  title,
  theme = Theme.dark,
  classNameWrap,
  classNameCollapse,
  classNameOpen,
  classNameToggleWrap,
  classNameToggleOpen,
  classNameToggleIconWrap,
  classNameToggleInner,
  onSelect: onSelectProps = () => {},
  dataTestId = '',
  fullWidth = true,
  canShowBorder = true,
  counter,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  const onSelect: (isOpen: boolean) => void = useCallback((isOpen: boolean) => {
    setIsOpen(isOpen);
    onSelectProps(isOpen);
  }, [onSelectProps]);

  return (
    <Box
      direction="column"
      className={cn(
        { [classes.accordion]: canShowBorder },
        { [classes.fullWidth]: fullWidth, [cn(classes.open, classNameOpen || '')]: isOpen },
        classes[theme],
        classNameWrap,
      )}
      data-testid={`accordion${dataTestId ? `-${dataTestId}` : ''}`}
    >
      <AccordionToggle
        {...{
          isOpen,
          title,
          icon,
          theme,
          onSelect,
          dataTestId,
          counter,
          classNameWrap: classNameToggleWrap,
          classNameIconWrap: classNameToggleIconWrap,
          classNameOpen: classNameToggleOpen,
          classNameInner: classNameToggleInner,
        }}
      />
      {isOpen && (
        <Box className={classNameCollapse} direction="column">
          {children}
        </Box>
      )}
    </Box>
  );
});
