import { useField } from 'formik';

import { FileUploader } from './FileUploader';
import { FileUploaderFormikProps } from './types';

export const FileUploaderFormik = ({ name, ...props }: FileUploaderFormikProps) => {
  const [, { error }, { setValue, setError }] = useField(name);

  return (
    <FileUploader
      {...props}
      setError={setError}
      setFile={setValue}
      error={error}
    />
  );
};
