import { memo, FC } from 'react';
import { Box } from 'uikit/Box';
import { OfferSelectedLine } from '../../OfferSelectedLine';
import { OfferSelectedItemPriceProps } from './types';
import classes from './OfferSelectedItemPrice.module.scss';

export const OfferSelectedItemPrice: FC<OfferSelectedItemPriceProps> = memo(({ priceFixed, pricePerHour }) => {
  return (
    <Box>
      {!!pricePerHour && <OfferSelectedLine className={classes.mr} label="Per Hour" value={`${pricePerHour} TEE`} />}
      {!!priceFixed && <OfferSelectedLine label="Fixed" value={`${priceFixed} TEE`} />}
    </Box>
  );
});