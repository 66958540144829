import {
  useEffect, useCallback, useMemo,
} from 'react';
import { OrderStatus } from '@super-protocol/sdk-js';
import { useGetOrdersCountQuery } from 'lib/features/orders';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import {
  addAlert, removeAlert, addAlertChecker,
} from 'lib/features/alerts';
import { alertsCheckerByIdSelector } from 'lib/features/alerts/selectors';
import { selectedAddressSelector } from 'lib/features/wallet';
import { isMobileSelector } from 'lib/features/windowSize';
import { TOfferType } from 'generated/types';
import { Notifications } from 'lib/features/alerts/types';

const ID = Notifications.REPLENISH_ORDERS;

export const useNotificationsLineReplenishOrders = () => {
  const isMobile = useAppSelector(isMobileSelector);
  const selectedAddress = useAppSelector(selectedAddressSelector);
  const oldOrdersIds: string[] | undefined = useAppSelector(alertsCheckerByIdSelector(ID));
  const dispatch = useAppDispatch();

  const ordersCountResponse = useGetOrdersCountQuery(
    {
      filter: {
        consumer: selectedAddress,
        offerType: TOfferType.TeeOffer,
        awaitingPayment: true,
        includeStatuses: [OrderStatus.Processing],
      },
      pagination: { first: Infinity },
    },
    {
      skip: !selectedAddress,
    },
  );
  const ordersResult = ordersCountResponse?.data?.result;
  const ordersCountAwaitingPayment = ordersResult?.pageData?.count;
  const newOrdersIds = useMemo(
    () => [...new Set((ordersResult?.page?.edges || []).map(({ node }) => node?.id).filter((id) => !!id))],
    [ordersResult],
  );
  const someNewOrders = useMemo(
    () => (newOrdersIds || []).some((newOrderId) => !(oldOrdersIds || []).includes(newOrderId as string)),
    [newOrdersIds, oldOrdersIds],
  );

  const onClose = useCallback(() => {
    dispatch(removeAlert({ id: ID }));
    dispatch(addAlertChecker({ id: ID, data: newOrdersIds }));
  }, [dispatch, newOrdersIds]);

  useEffect(() => {
    if (someNewOrders && !isMobile) {
      dispatch(addAlert({
        id: ID,
        mode: 'orange',
      }));
    } else {
      dispatch(removeAlert({ id: ID }));
    }
  }, [someNewOrders, newOrdersIds, dispatch, isMobile]);

  return {
    ordersCountAwaitingPayment,
    onClose,
  };
};
