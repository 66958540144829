import { Modes } from './types';

export const agreement = 'Our service does not store your phrase, write it down in a safe place';

export const modeTitle = {
  own: 'Enter your own',
  generate: 'Generate',
};

export const getTitleByMode = (mode: Modes): string => modeTitle[mode];

export const placeholder = 'BIP-39 Passphrase with 24 words...';
