import {
  useMemo, forwardRef, memo, useCallback,
} from 'react';
import cn from 'classnames';
import xss from 'xss';
import { Theme } from 'uikit/common/types';
import { HtmlBoxProps } from './types';
import classes from './HtmlBox.module.scss';
import { defaultXssOptions } from './helpers';

export const HtmlBox = memo(forwardRef<HTMLDivElement, HtmlBoxProps>(({
  text, ellipsis = true, theme = Theme.dark, className, xssOptions = defaultXssOptions,
}, ref) => {
  const markup = useMemo(() => ({
    __html: xss(text, xssOptions),
  }), [text, xssOptions]);
  const onClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={markup} // eslint-disable-line react/no-danger
      className={cn(classes.wrap, className, classes[theme], { [classes.ellipsis]: ellipsis })}
      ref={ref}
      onClick={onClick}
      role="textbox"
      tabIndex={0}
      onKeyDown={() => {}}
    />
  );
}));

export default HtmlBox;
