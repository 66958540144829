import { memo, FC } from 'react';
import { OfferSelectedItem } from 'uikit/Offer/OfferSelected/OfferSelectedItem';
import { Theme } from 'uikit/common/types';
import { FileUploaderFileNameDescription } from './FileUploaderFileNameDescription';
import { FileUploaderFileNameProps } from './types';

export const FileUploaderFileName: FC<FileUploaderFileNameProps> = memo(({
  fileName, className, onDelete, theme = Theme.dark,
}) => {
  return (
    <OfferSelectedItem
      id={fileName}
      description={<FileUploaderFileNameDescription theme={theme} fileName={fileName} />}
      className={className}
      onDelete={onDelete}
      theme={theme}
    />
  );
});