import {
  memo,
} from 'react';
import { Box } from 'uikit/Box';
import { NotificationsLine } from 'components/NotificationsLine';
import { OfferModalOrMobile } from 'components/OfferModalOrMobile';
import { Top } from './Top';
import classes from './Main.module.scss';
import { MainProps } from './types';
import { Content } from './Content';

export const Main = memo(({ page, prefetch }: MainProps) => {
  return (
    <Box className={classes.wrap} alignItems="flex-start" direction="column" Tag="main">
      <NotificationsLine />
      <Top active={page} className={classes.top} />
      <Content active={page} prefetch={prefetch} />
      <OfferModalOrMobile />
    </Box>
  );
});
