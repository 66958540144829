import {
  memo, ReactElement, useState, useCallback,
} from 'react';
import cn from 'classnames';
import { Box } from 'uikit/Box';
import { InputUi } from 'uikit/Input';
import { Theme } from 'uikit/common/types';
import { MnemonicGeneratorUiProps, Modes } from './types';
import { placeholder, getTitleByMode } from './helpers';
import { PhraseGenerator } from './PhraseGenerator';
import classes from './MnemonicGeneratorUi.module.scss';

export const MnemonicGeneratorUi = memo<MnemonicGeneratorUiProps>(({
  phrase,
  error,
  isInvalid,
  value,
  onChange: onChangeProps,
  mode: modeProps = Modes.generate,
  setMode: setModeProps = () => {},
  onGenerate,
  theme = Theme.dark,
  onCopy,
}): ReactElement => {
  const [mode, setMode] = useState<Modes>(modeProps);
  const changeMode = useCallback((k: Modes) => {
    setMode(k);
    setModeProps(k);
  }, [setModeProps]);
  const onChange = useCallback((val: string) => {
    onChangeProps?.(val);
  }, [onChangeProps]);
  const handlerOnKeyDown = useCallback(() => {}, []);

  return (
    <Box direction="column" className={classes[theme]}>
      <Box className={classes.modeswitcher}>
        {Object.values(Modes).map((value, idx) => (
          <div
            role="button"
            tabIndex={idx}
            key={value}
            className={cn(
              classes.mode,
              { [classes['mode-active']]: mode === value },
              { [classes['mode-zero']]: idx === 0 },
            )}
            onClick={() => changeMode(value)}
            onKeyDown={handlerOnKeyDown}
          >
            {getTitleByMode(value)}
          </div>
        ))}
      </Box>
      {mode === Modes.generate
        ? (
          <PhraseGenerator phrase={phrase} onGenerate={onGenerate} theme={theme} onCopy={onCopy} />
        )
        : (
          <InputUi
            {...{
              value,
              error,
              isInvalid,
              showError: isInvalid,
              onChange,
              classNameError: classes.inputError,
              placeholder,
              classNameInput: classes.input,
              theme,
            }}
            as="textarea"
          />
        )}
    </Box>
  );
});
