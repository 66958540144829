import { useMemo, useState } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import cn from 'classnames';

import { Box } from 'uikit/Box';
import { Icon } from 'uikit/Icon';
import { Notification } from 'uikit/Notification';
import { Theme } from 'uikit/common/types';
import { FileUploaderCore } from './FileUploaderCore';
import { FileUploaderProps } from './types';
import classes from './FileUploader.module.scss';
import { getMbFromBytes } from './helpers';

export const FileUploader = ({
  disabled,
  error,
  setFile,
  setError = () => {},
  className,
  maxSize,
  formats,
  theme = Theme.dark,
  validator,
  dataTestId = 'fileuploader',
}: FileUploaderProps) => {
  const [validating, setValidating] = useState(false);
  const options = useMemo((): DropzoneOptions => {
    return {
      multiple: false,
      disabled,
      onDrop: async (acceptedFiles) => {
        try {
          setValidating(true);
          const file = acceptedFiles[0];
          const { size, name } = file || {};
          if (maxSize && size > maxSize) {
            throw new Error(`File size should not exceed ${getMbFromBytes(maxSize)}MB`);
          }
          if (formats?.length && !formats.some((format) => name.endsWith(format))) {
            throw new Error(`File format must be ${formats.join(', ')}`);
          }
          if (validator) {
            await validator(file);
          }
          setError?.(undefined);
          setFile?.(file);
        } catch (e) {
          setError?.(e);
        } finally {
          setValidating(false);
        }
      },
      onDropRejected: (result) => {
        const errors = result.map(({ errors }) => errors).flat();
        if (errors.length) {
          setError(errors[0]?.message);
        }
      },
    };
  }, [disabled, setError, setFile, formats, maxSize, validator]);

  return (
    <Box direction="column" className={cn(className, classes[theme])} data-testid={dataTestId}>
      <FileUploaderCore {...{
        options,
        error: !!error,
        theme,
        disabled: validating,
      }}
      >
        <Box alignItems="center">
          <Icon name="clip" width={16} height={16} className={classes.icon} />
          <span className={classes.addFile}>Add your own</span>
        </Box>
      </FileUploaderCore>
      {!!error && <Notification theme={theme} variant="error">{error}</Notification>}
    </Box>
  );
};
