import dynamic from 'next/dynamic';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import BigNumber from 'bignumber.js';
import { getOfferTextBtn } from 'common/utils';
import {
  TeeOffer, MatchingSlot, MatchingOptions, TeeOffersAndSLots,
} from 'generated/types';
import { Box } from 'uikit/Box';
import { TooltipDeposit } from 'common/components/TooltipDeposit';
import { AddOfferToFormProps, DeleteOfferFromFormProps } from 'lib/features/createOrder/hooks/useAddOfferToForm';
import { FieldsBuildOrderForm, BuildOrderForm } from 'lib/features/createOrder/types';
import { FormFilters, FormFiltersCompute } from 'lib/features/filters/types';
import { checkAddOffer, getSlotsFromTeeOffersAndSLots, getPreparedSlots } from 'lib/features/createOrder/helpers';
import {
  getOfferSlotValueFormatted, getOfferSlotExtension, getDeposit,
} from 'common/utils/slots';
import { Column } from '../Table/types';
import { getComputeColumns } from './helpers';

const AdderBtnOffer = dynamic(() => import('../../../AdderBtn/AdderBtnOffer/AdderBtnOffer'), { ssr: false });

export const columnHelper = createColumnHelper<Column>();

export interface GetColumnsProps {
  onAddFormOffer: (props: AddOfferToFormProps) => void;
  buildOrderForm: BuildOrderForm;
  onDeleteFormOffer: (props: DeleteOfferFromFormProps) => void;
  loading?: boolean;
  minDeposit?: number;
  extendsColumns?: string[];
  isMobile: boolean;
  filters?: FormFilters | null;
}

export const getDepositTee = (teeOfferAndSlots: TeeOffersAndSLots, lease: number): BigNumber => {
  const { slotResult, optionsResult } = teeOfferAndSlots || {};
  const slotDeposit = getDeposit({ slotUsage: slotResult?.slot?.usage, lease: lease || 1, count: slotResult?.multiplier });
  const { fixed, perHourByLease } = (optionsResult?.optionResults || []).reduce((acc, option) => getDeposit({
    slotUsage: option?.usage, lease: lease || 1, deposit: acc, count: option?.count,
  }), slotDeposit);
  return new BigNumber(perHourByLease || 0).plus(fixed || 0);
};

export const getColumns = (props: GetColumnsProps): ColumnDef<Column>[] => {
  const {
    onAddFormOffer, buildOrderForm, loading, onDeleteFormOffer, isMobile, filters,
  } = props;
  const computeColumns = getComputeColumns(isMobile);

  return [
    columnHelper.accessor('teeOffer', {
      ...computeColumns.offerId,
      cell: (column) => {
        const { id } = column.getValue() as TeeOffer || {};
        return id || '-';
      },
    }),
    columnHelper.accessor('teeOffer', {
      ...computeColumns.name,
      cell: (column) => {
        const { teeOfferInfo } = column.getValue() as TeeOffer || {};
        const { name } = teeOfferInfo || {};
        return name || '-';
      },
    }),
    columnHelper.accessor('teeOffer', {
      ...computeColumns.provider,
      cell: (column) => {
        const { providerInfo } = column.getValue() as TeeOffer || {};
        const { name } = providerInfo || {};
        return name || '-';
      },
    }),
    columnHelper.accessor('slotResult', {
      ...computeColumns.cpuCores,
      cell: (column) => {
        const { slot, multiplier } = column.getValue() as MatchingSlot || {};
        const { info } = slot || {};
        const { cpuCores } = info || {};
        return getOfferSlotValueFormatted('cpuCores', (cpuCores || 0) * (multiplier || 0));
      },
    }),
    columnHelper.accessor('slotResult', {
      ...computeColumns.ram,
      cell: (column) => {
        const { slot, multiplier } = column.getValue() as MatchingSlot || {};
        const { info } = slot || {};
        const { ram } = info || {};
        return `${getOfferSlotValueFormatted('ram', (ram || 0) * (multiplier || 0))} ${getOfferSlotExtension('ram')}`;
      },
    }),
    columnHelper.accessor('slotResult', {
      ...computeColumns.diskUsage,
      cell: (column) => {
        const { slot, multiplier } = column.getValue() as MatchingSlot || {};
        const { info } = slot || {};
        const { diskUsage } = info || {};
        return `
        ${getOfferSlotValueFormatted('diskUsage', (diskUsage || 0) * (multiplier || 0))}
        ${getOfferSlotExtension('diskUsage', diskUsage)}
        `;
      },
    }),
    columnHelper.accessor('optionsResult', {
      ...computeColumns.bandwidth,
      cell: (column) => {
        const { cumulativeValues } = column.getValue() as MatchingOptions || {};
        const { bandwidth } = cumulativeValues || {};
        return `${getOfferSlotValueFormatted('bandwidth', bandwidth)} ${getOfferSlotExtension('bandwidth', bandwidth)}`;
      },
    }),
    columnHelper.accessor('optionsResult', {
      ...computeColumns.traffic,
      cell: (column) => {
        const { cumulativeValues } = column.getValue() as MatchingOptions || {};
        const { traffic } = cumulativeValues || {};
        return `${getOfferSlotValueFormatted('traffic', traffic)} ${getOfferSlotExtension('traffic', traffic)}`;
      },
    }),
    columnHelper.accessor('optionsResult', {
      ...computeColumns.externalPort,
      cell: (column) => {
        const { cumulativeValues } = column.getValue() as MatchingOptions || {};
        const { externalPort } = cumulativeValues || {};
        return getOfferSlotValueFormatted('externalPort', externalPort);
      },
    }),
    columnHelper.accessor('slotResult', {
      ...computeColumns.minTimeMinutes,
      cell: (column) => {
        const { slot } = column.getValue() as MatchingSlot || {};
        const { usage } = slot || {};
        const { minTimeMinutes } = usage || {};
        return `
        ${getOfferSlotValueFormatted('minTimeMinutes', minTimeMinutes)}
        ${getOfferSlotExtension('minTimeMinutes', minTimeMinutes)}`;
      },
    }),
    columnHelper.accessor('slotResult', {
      ...computeColumns.maxTimeMinutes,
      cell: (column) => {
        const { slot } = column.getValue() as MatchingSlot || {};
        const { usage } = slot || {};
        const { maxTimeMinutes } = usage || {};
        return `
        ${getOfferSlotValueFormatted('maxTimeMinutes', maxTimeMinutes)}
        ${getOfferSlotExtension('maxTimeMinutes', maxTimeMinutes)}`;
      },
    }),
    columnHelper.display({
      ...computeColumns.pricePerHour,
      cell: ({ row }) => {
        const original = row.original as TeeOffersAndSLots;
        const { price } = original || {};
        return price ? <TooltipDeposit deposit={price} currency="TEE" tooltip={false} /> : '-';
      },
    }),
    columnHelper.display({
      ...computeColumns.deposit,
      cell: ({ row }) => {
        const deposit = getDepositTee(
          row.original as TeeOffersAndSLots,
          (filters as FormFiltersCompute)?.lease?.time ?? 0,
        );
        return deposit ? <TooltipDeposit deposit={deposit} currency="TEE" tooltip={false} /> : '-';
      },
    }),
    columnHelper.accessor('teeOffer', {
      ...computeColumns.ordersInQueue,
      cell: (column) => {
        const { stats } = column.getValue() as TeeOffer || {};
        const { ordersInQueue } = stats || {};
        return ordersInQueue ?? 0;
      },
    }),
    columnHelper.accessor('teeOffer', {
      ...computeColumns.icon,
      cell: (column) => {
        const original = column.row.original as TeeOffersAndSLots;
        const { teeOffer } = original || {};
        const { id, enabled, inactive } = teeOffer || {};
        const { isAdded } = checkAddOffer(id, buildOrderForm, FieldsBuildOrderForm.tee, getSlotsFromTeeOffersAndSLots(original));
        return (
          <Box justifyContent="flex-end">
            <AdderBtnOffer
              onAdd={() => onAddFormOffer({
                value: id,
                field: FieldsBuildOrderForm.tee,
                data: teeOffer,
                slots: getPreparedSlots(original),
              })}
              disabled={loading}
              isAdded={isAdded}
              onDelete={() => onDeleteFormOffer({
                field: FieldsBuildOrderForm.tee,
                value: id,
              })}
              offerTextBtn={getOfferTextBtn({ id, enabled, inactive })}
              dataTestId={`offer-${id}`}
            />
          </Box>
        );
      },
    }),
  ];
};
