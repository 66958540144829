import { Theme } from 'uikit/common/types';

export enum Status {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  COMPLETED = 'completed',
}

export interface CounterProps {
  count: number;
  status: Status;
  theme?: Theme;
}