import React, { memo, FC, useMemo } from 'react';
import cn from 'classnames';
import { Theme } from 'uikit/common/types';
import { Box } from 'uikit/Box';
import { Icon } from 'uikit/Icon';
import { Spinner } from 'uikit/Spinner';
import { ProcessStatusProps, Status } from './types';
import classes from './ProcessStatus.module.scss';

export const ProcessStatus: FC<ProcessStatusProps> = memo(({ status, className, theme = Theme.dark }) => {
  const renderStatus = useMemo(() => {
    switch (status) {
      case Status.PROGRESS:
        return (
          <>
            <Spinner animation="border" className={classes.spinner} />
            <span className={classes.progressText}>In progress</span>
          </>
        );
      case Status.DONE:
        return (
          <>
            <Icon
              width={12}
              name="check_small"
              className={classes.createdIcon}
            />
            <span className={classes.createdText}>Done</span>
          </>
        );
      case Status.QUEUE:
        return (
          <>
            <Icon
              width={12}
              name="refresh"
              className={classes.queueIcon}
            />
            <span className={classes.queueText}>In queue</span>
          </>
        );
      case Status.ERROR:
        return (
          <>
            <Icon
              width={12}
              name="close"
              className={classes.errorIcon}
            />
            <span className={classes.errorText}>Error</span>
          </>
        );
      default:
        return null;
    }
  }, [status]);
  return <Box alignItems="center" className={cn(classes.wrap, className, classes[theme])}>{renderStatus}</Box>;
});
