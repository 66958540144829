import { memo, FC, useMemo } from 'react';
import { Box } from 'uikit/Box';
import { Steps } from 'lib/features/createOrder/types';
import { BuildOrder } from './BuildOrder';
import { FormStepperProps } from './types';
import { CreatePassphrase } from './CreatePassphrase';
import { ConfirmTransactions } from './ConfirmTransactions';

export const FormStepper: FC<FormStepperProps> = memo(({ active, onClickLink, className }) => {
  const renderActiveStep = useMemo(() => {
    switch (active) {
      case Steps.BUILD_ORDER:
        return <BuildOrder onClickLink={onClickLink} />;
      case Steps.CREATE_PASSPHRASE:
        return <CreatePassphrase />;
      case Steps.CONFIRM_TRANSACTIONS:
        return <ConfirmTransactions />;
      default:
        return null;
    }
  }, [active, onClickLink]);

  return (
    <Box className={className}>
      {renderActiveStep}
    </Box>
  );
});