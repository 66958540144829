import {
  memo, useMemo, useState, useCallback, useRef,
} from 'react';
import { Formik, FormikProps, FormikState } from 'formik';
import dynamic from 'next/dynamic';
import { Offer, TeeOffer } from 'generated/types';
import { themeSelector } from 'lib/features/theme';
import { Box } from 'uikit/Box';
import { Cards } from 'uikit/Cards';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { buildOrderFormSelector } from 'lib/features/createOrder/selectors';
import { isMobileSelector } from 'lib/features/windowSize';
import { useExtendedBuildOrderForm } from 'lib/features/createOrder/hooks/useExtendedBuildOrderForm';
import { offerViewContolPanelSelector } from 'lib/features/controlPanel';
import { AddOfferToFormProps, useAddOfferToForm } from 'lib/features/createOrder/hooks/useAddOfferToForm';
import {
  Blocks, Form, FormControlPanel, FormFilters,
} from 'lib/features/filters/types';
import { getPreparedForm } from 'lib/features/filters/helpers';
import { pageFiltersSelector } from 'lib/features/filters/selectors';
import { updatePageFilters } from 'lib/features/filters';
import { OffersPages } from 'common/types/pages';
import { useOnClickRow } from 'common/hooks/useOnClickRow';
import { OfferTabs } from 'components/OfferModal/Offer/Left/types';
import {
  getValidationSchema, getColumns, getFields, INTERVAL, checkIsDifferentOfferPriceType,
} from './helpers';
import { Filters } from './Filters';
import classes from './Content.module.scss';
import useOffers from './hooks/useOffers';
import { ContentProps } from './types';
import { ControlPanel } from './ControlPanel';

const Table = dynamic(() => import('./Table/Table'), { ssr: false });

export const Content = memo(({ active, prefetch }: ContentProps) => {
  const isMobile = useAppSelector(isMobileSelector);
  const previousFormRef = useRef<string>('');
  const dispatch = useAppDispatch();
  const theme = useAppSelector(themeSelector);
  const filters = useAppSelector(pageFiltersSelector(active));

  const {
    addOfferToForm, attentionModal, loading, deleteOfferFromForm,
  } = useAddOfferToForm();

  const buildOrderForm = useAppSelector(buildOrderFormSelector);
  const { loading: loadingExtending, extendedBuildOrderForm } = useExtendedBuildOrderForm(buildOrderForm);
  const offerView = useAppSelector(offerViewContolPanelSelector);
  const [isValidating, setIsValidating] = useState(false);
  const { onClickRow, openModal } = useOnClickRow();

  const {
    data, refetch, fetchNextPage: fetchNextPageOffers, isFetchingNextPage, pageInfo, pageData,
  } = useOffers(active, prefetch?.data);

  const handleSubmitForm = useCallback((formikProps: FormikProps<Form>) => async (newValues?: Form | null) => {
    const { submitForm, values, resetForm } = formikProps || {};
    const newFilters = newValues || values;
    // prevent refetch if equal filters
    if (previousFormRef.current === JSON.stringify(newFilters)) {
      return;
    }
    previousFormRef.current = JSON.stringify(newFilters);
    if (newValues) {
      resetForm(newValues as Partial<FormikState<Form>>);
    }
    dispatch(updatePageFilters({ page: active, filters: newFilters }));
    // execute after main thread completed
    setTimeout(() => {
      submitForm();
      setIsValidating(true);
    }, 1);
  }, [active, dispatch]);

  const handleSubmitFilters = useCallback((formikProps: FormikProps<Form>) => async (newValues?: FormFilters) => {
    const { values: oldValues } = formikProps || {};
    handleSubmitForm(formikProps)(newValues ? { ...oldValues, [Blocks.filters]: newValues } : oldValues);
  }, [handleSubmitForm]);

  const handleSubmitControlPanel = useCallback((formikProps: FormikProps<Form>) => async (newValues?: FormControlPanel) => {
    const { values: oldValues } = formikProps || {};
    handleSubmitForm(formikProps)(newValues ? { ...oldValues, [Blocks.controlPanel]: newValues } : oldValues);
  }, [handleSubmitForm]);

  const onSubmitForm = useCallback((form: Form) => {
    refetch(getPreparedForm(form, extendedBuildOrderForm, active));
  }, [refetch, extendedBuildOrderForm, active]);

  const fetchNextPage = useCallback(({ values }) => () => {
    fetchNextPageOffers(getPreparedForm(values, extendedBuildOrderForm, active));
  }, [fetchNextPageOffers, extendedBuildOrderForm, active]);

  const validationSchema = useMemo(() => getValidationSchema(), []);

  const onAddFormOffer = useCallback(async (props: AddOfferToFormProps) => {
    const { data } = props || {};
    if ((data as TeeOffer)?.teeOfferInfo) {
      await addOfferToForm(props);
    } else {
      const offerId = (data as Offer)?.id;
      if (checkIsDifferentOfferPriceType((data as Offer)?.slots)) {
        openModal(offerId, {}, OfferTabs.pricing);
      } else {
        await addOfferToForm(props);
      }
    }
  }, [addOfferToForm, openModal]);

  const columns = useMemo(
    () => getColumns({
      active,
      onDeleteFormOffer: deleteOfferFromForm,
      onAddFormOffer,
      buildOrderForm: extendedBuildOrderForm,
      loading: loading || loadingExtending,
      theme,
      isMobile,
      filters: filters?.filters,
    }),
    [
      active,
      onAddFormOffer,
      extendedBuildOrderForm,
      loading,
      loadingExtending,
      theme,
      deleteOfferFromForm,
      isMobile,
      filters,
    ],
  );

  const fields = useMemo(() => (
    getFields({
      active,
      onAddFormOffer,
      onDeleteFormOffer: deleteOfferFromForm,
      buildOrderForm: extendedBuildOrderForm,
      loading: loading || loadingExtending,

      theme,
    })
  ), [active, loading, onAddFormOffer, extendedBuildOrderForm, loadingExtending, theme, deleteOfferFromForm]);

  const classNames = useMemo(() => ({
    tr: classes.tr,
  }), []);

  const getRowId = useCallback((data) => {
    const { original } = data;
    return original?.id || original?.teeOffer?.id;
  }, []);

  const isShowCards = active !== OffersPages.compute && (offerView?.[active] === 'cards' || isMobile);

  return (
    <>
      {attentionModal}
      <Formik
        validateOnChange={isValidating}
        validateOnBlur={isValidating}
        initialValues={filters}
        onSubmit={onSubmitForm}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formikProps) => {
          return (
            <Box className={classes.wrap}>
              <Filters
                className={classes.filters}
                name={Blocks.filters}
                active={active}
                onSubmit={handleSubmitFilters(formikProps)}
                interval={INTERVAL}
                formOffers={extendedBuildOrderForm}
              />
              <Box direction="column" className={classes.content}>
                <ControlPanel
                  name={Blocks.controlPanel}
                  columns={columns}
                  active={active}
                  onSubmit={handleSubmitControlPanel(formikProps)}
                  showCount={data?.length}
                  totalCount={pageData?.count}
                  interval={INTERVAL}
                />
                {isShowCards
                  ? (
                    <Cards
                      fields={fields}
                      active={active}
                      data={data}
                      onClickCard={onClickRow}
                      fetchNextPage={fetchNextPage(formikProps)}
                      hasNextPage={pageInfo?.hasNextPage}
                      isFetchingNextPage={isFetchingNextPage}
                      theme={theme}
                    />
                  ) : (
                    <Table
                      columns={columns}
                      active={active}
                      data={data}
                      fetchNextPage={fetchNextPage(formikProps)}
                      hasNextPage={pageInfo?.hasNextPage}
                      isFetchingNextPage={isFetchingNextPage}
                      onClickRow={onClickRow}
                      classNames={classNames}
                      getRowId={getRowId}
                    />
                  )}
              </Box>
            </Box>
          );
        }}
      </Formik>
    </>
  );
});