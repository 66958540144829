import { Fragment, memo } from 'react';
import cn from 'classnames';
import { Theme } from 'uikit/common/types';
import { Box } from 'uikit/Box';
import { OfferSelectedProps } from './types';
import { OfferSelectedItem } from './OfferSelectedItem';
import classes from './OfferSelected.module.scss';

export const OfferSelected = memo(({
  list, onDelete, className, theme = Theme.dark, onClickName, dataTestId,
}: OfferSelectedProps) => {
  return (
    <Box direction="column" className={cn(classes[theme], className)}>
      {list.map(({
        id, description, subItems, slots,
      }) => (
        <Fragment key={id}>
          <OfferSelectedItem {...{
            id, description, onDelete, className: classes.item, theme, onClickName, dataTestId, slots,
          }}
          />
          {!!subItems?.length && (
            <Box direction="column">
              {subItems.map((item) => (
                <Box alignItems="center" className={classes.subItem} key={item.id}>
                  <OfferSelectedItem
                    key={item.id}
                    {...{
                      id: item.id,
                      description: item.description,
                      className: classes.element,
                      theme,
                      onClickName,
                      dataTestId,
                      slots: item.slots,
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Fragment>
      ))}
    </Box>
  );
});
