import BigNumber from 'bignumber.js';
import { getSumDeposit } from 'common/utils/sdk';
import { SimpleItem } from 'uikit/Offer/OfferSelected/types';
import { GetOffersListResult } from '../types';

export type Price = {
  fixed: BigNumber | null;
  perHour: BigNumber | null;
  perHourByLease: BigNumber | null;
}

const getInitialPrice = (): Price => ({
  fixed: new BigNumber(0),
  perHour: new BigNumber(0),
  perHourByLease: new BigNumber(0),
});

const getPrice = (offers: SimpleItem[]): Price => {
  return offers.reduce((acc, { slots }) => ({
    perHour: getSumDeposit(acc.perHour, slots?.perHour),
    fixed: getSumDeposit(acc.fixed, slots?.fixed),
    perHourByLease: getSumDeposit(acc.perHourByLease, slots?.perHourByLease),
  }), getInitialPrice());
};

export const calcPrice = (formOffers: GetOffersListResult): Price => {
  if (!formOffers) return getInitialPrice();

  return Object.keys(formOffers).reduce((acc, item) => {
    const price = getPrice(formOffers[item] || []);
    const { fixed, perHour, perHourByLease } = price;
    return {
      fixed: getSumDeposit(acc.fixed, fixed),
      perHour: getSumDeposit(acc.perHour, perHour),
      perHourByLease: getSumDeposit(acc.perHourByLease, perHourByLease),
    };
  }, getInitialPrice());
};

export const calcDeposit = (price: Price, minDeposit = '0', hours = 0): BigNumber | null => {
  const sum = getSumDeposit(price.fixed, hours ? price.perHourByLease : 0);
  if (!sum) return new BigNumber(minDeposit);
  return sum.isLessThanOrEqualTo(minDeposit) ? new BigNumber(minDeposit) : sum;
};
