import { genericMemo } from 'uikit/common/utils';
import { Dropdown } from '../../Dropdown';
import { TabsDropdownProps } from './types';

function Component<TValue>({
  list,
  active,
  className,
  classNameDropdownToggle,
  classNameDropdownMenu,
  theme,
}: TabsDropdownProps<TValue>) {
  return (
    <Dropdown
      isShowActive={false}
      active={active}
      items={list}
      className={className}
      theme={theme}
      classNameDropdownToggle={classNameDropdownToggle}
      classNameDropdownMenu={classNameDropdownMenu}
    />
  );
}

export const TabsDropdown = genericMemo(Component);
