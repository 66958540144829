import { useCallback, useState } from 'react';
import { CryptoAlgorithm, Encoding } from '@super-protocol/dto-js/build/enum';
import {
  Cipher, EncryptionWithIV, HashAlgorithm, Hash,
} from '@super-protocol/dto-js';
import { generateRandomKeys } from 'common/utils/crypto';
import { CryptoBrowserify } from 'common/utils/crypto/CryptoBrowserify';

export interface UseEncryptFileResultEncryptFileResult {
    encryptedStream: ReadableStream;
    encryption: EncryptionWithIV;
    getAuthTag: () => Buffer;
    getHash: () => Hash;
}

export interface UseEncryptFileResult {
    encryptFile: (file: File) => Promise<UseEncryptFileResultEncryptFileResult>;
    encrypting: boolean;
}

export const useEncryptFile = (): UseEncryptFileResult => {
  const [encrypting, setEncrypting] = useState(false);
  const encryptFile = useCallback(async (file: File): Promise<UseEncryptFileResultEncryptFileResult> => {
    setEncrypting(true);
    const { privateKeyBase64 } = await generateRandomKeys();
    const fileStream = file.stream();
    const {
      stream: hashedStream,
      getHash,
    } = await CryptoBrowserify.createHashFromStream(
      fileStream as unknown as ReadableStream<Uint8Array>,
      HashAlgorithm.SHA256,
      Encoding.hex,
    );
    const { stream: encryptedStream, encryption, getAuthTag } = await CryptoBrowserify.encryptStream(
      hashedStream,
      {
        key: privateKeyBase64,
        algo: CryptoAlgorithm.AES,
        encoding: Encoding.base64,
        cipher: Cipher.AES_256_GCM,
      },
    );
    try {
      return {
        encryptedStream,
        encryption,
        getAuthTag,
        getHash,
      };
    } finally {
      setEncrypting(false);
    }
  }, []);
  return {
    encryptFile,
    encrypting,
  };
};
