import {
  useEffect,
  useCallback,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useAppDispatch } from 'lib/hooks';
import { genericMemo } from 'uikit/common/utils';
import { FormikPersistReduxProps } from './types';

function Component<Values>({
  values, action, exclude = [], step,
}: FormikPersistReduxProps<Values>) {
  const dispatch = useAppDispatch();
  const onChange = useCallback(() => {
    dispatch(action({ values, exclude, step }));
  }, [values, exclude, step, dispatch, action]);
  const onChangeDebounce = useDebouncedCallback(onChange, 150);
  useEffect(() => {
    onChangeDebounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  return null;
}

export const FormikPersistRedux = genericMemo(Component);