import { useMemo } from 'react';
import { useLazyGetOffersQuery } from 'lib/features/offers';
import { useLazyGetMatchingTeeSlotsQuery } from 'lib/features/teeOffers';
import useLazyPagination, { ResultType, FetchResult } from 'common/hooks/useLazyPagination';
import { Node, OffersPages } from 'common/types/pages';

const useOffers = (active: OffersPages, prefetch?: ResultType<Node>): FetchResult<Node> => {
  const config = useMemo(() => {
    switch (active) {
      case OffersPages.compute:
        return { useLazyQuery: useLazyGetMatchingTeeSlotsQuery, prefetch, nextPage: null };
      default:
        return { useLazyQuery: useLazyGetOffersQuery, prefetch };
    }
  }, [prefetch, active]);
  return useLazyPagination<Node>(config);
};

export default useOffers;
