import { memo, FC } from 'react';
import cn from 'classnames';
import { Box } from 'uikit/Box';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import classes from './Header.module.scss';
import { HeaderProps } from './types';

export const Header: FC<HeaderProps> = memo(({ className }) => {
  const theme = useAppSelector(themeSelector);
  return <Box className={cn(classes.wrap, classes[theme], className)}>Your Order</Box>;
});