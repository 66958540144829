import { useMemo } from 'react';
import { WorkflowCreatedDocument } from 'generated/schemas/subscriptions';
import { useWebSocket } from 'common/hooks/useWebSocket';
import { WorkflowCreatedPayload } from 'generated/types';

export interface OnWorkflowCreated { data: { workflowCreated: WorkflowCreatedPayload }}

export const getVariablesSubscription = (consumer: string, externalId: string) => ({
  input: {
    filter: {
      consumer,
      externalId,
    },
  },
});

export const useWorkflowSubscription = (
  consumer: string | undefined,
  externalId: string | undefined,
  onCreateWorkflow?: (data: OnWorkflowCreated, close: () => void) => void,
) => {
  const variables = useMemo(
    () => {
      return (consumer && externalId ? getVariablesSubscription(consumer, externalId) : undefined);
    },
    [consumer, externalId],
  );
  return useWebSocket<OnWorkflowCreated>({
    variables,
    operationName: 'WorkflowCreated',
    query: WorkflowCreatedDocument,
    onMessageData: onCreateWorkflow,
  });
};