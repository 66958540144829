import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { useCallback, useState } from 'react';
import { useAppDispatch } from 'lib/hooks';
import { trackEvent } from 'lib/features/events/thunks';
import { ErrorWithOriginal } from 'common/utils';
import { postUploadFileS3 } from 'connectors/server';
import getConfig from 'config';
import {
  Hash,
} from '@super-protocol/dto-js';
import { getErrorMessage } from 'common/utils/sdk';

export interface UploadFileByS3Props {
    stream: ReadableStream;
    fileName: string;
}

export interface UploadFileProps {
    stream: ReadableStream;
    fileName: string;
    getAuthTag: () => Buffer;
    getHash: () => Hash;
}

export interface UploadFileResult {
  hash: Hash;
  mac: Buffer;
}

export interface UseFileUploaderResult {
    uploadFileServer: (props: UploadFileProps) => Promise<UploadFileResult>;
    uploadFileStream: (props: UploadFileProps) => Promise<UploadFileResult>;
    uploading: boolean;
}

export const useFileUploader = (): UseFileUploaderResult => {
  const dispatch = useAppDispatch();
  const [uploading, setUploading] = useState(false);

  const uploadFileStream = useCallback(async (props: UploadFileProps) => {
    setUploading(true);

    const {
      stream, fileName, getHash, getAuthTag,
    } = props;

    try {
      const {
        NEXT_PUBLIC_S3_UPLOAD_ACCESS_KEY_ID,
        NEXT_PUBLIC_S3_UPLOAD_ACCESS_SECRET_KEY,
        NEXT_PUBLIC_S3_REGION,
        NEXT_PUBLIC_S3_ENDPOINT,
        NEXT_PUBLIC_S3_BUCKET,
      } = getConfig();

      const s3Client = new S3Client({
        region: NEXT_PUBLIC_S3_REGION,
        endpoint: NEXT_PUBLIC_S3_ENDPOINT,
        credentials: {
          accessKeyId: NEXT_PUBLIC_S3_UPLOAD_ACCESS_KEY_ID,
          secretAccessKey: NEXT_PUBLIC_S3_UPLOAD_ACCESS_SECRET_KEY,
        },
        forcePathStyle: true,
      });

      const upload = new Upload({
        client: s3Client,
        params: {
          Bucket: NEXT_PUBLIC_S3_BUCKET,
          Key: fileName,
          Body: stream,
        },
      });

      await upload.done();
      dispatch(trackEvent({ eventType: 'order_creation_file_upload', property: { result: 'success' } }));
    } catch (e) {
      const error = new ErrorWithOriginal(e, 'File upload error');
      dispatch(trackEvent({
        eventType: 'order_creation_file_upload',
        property: { result: 'error', error: getErrorMessage(error) ?? '', errorStack: (e as Error)?.stack },
      }));
      throw error;
    } finally {
      setUploading(false);
    }

    return { hash: getHash(), mac: getAuthTag() };
  }, [dispatch]);

  const uploadChunk = async (chunk: Blob | null, fileName: string, chunksName?: string): Promise<string> => {
    const formData = new FormData();
    formData.append('isLastChunk', chunk ? 'false' : 'true');
    formData.append('fileName', fileName);

    if (chunksName) {
      formData.append('chunksName', chunksName);
    }

    if (chunk) {
      formData.append('file', chunk);
    }

    const response = await postUploadFileS3(formData);

    if (!response?.ok) {
      if (response?.status === 413) {
        throw new Error(await response.text().catch(() => 'Request Entity Too Large'));
      }
      throw new Error((await response.json().catch(() => null))?.error || response?.statusText);
    }

    return (await response.json())?.chunksName;
  };

  const uploadFileServer = useCallback(async (props: UploadFileProps): Promise<UploadFileResult> => {
    setUploading(true);
    try {
      const {
        stream, fileName, getAuthTag, getHash,
      } = props;
      const reader = stream.getReader();
      let mac: Buffer;
      let hash: Hash;
      let chunksName = '';
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          hash = getHash();
          mac = getAuthTag();
          await uploadChunk(null, fileName, chunksName);
          break;
        }
        const chunk = new Blob([value]);
        chunksName = await uploadChunk(chunk, fileName, chunksName);
      }
      dispatch(trackEvent({ eventType: 'order_creation_file_upload', property: { result: 'success' } }));
      return { mac, hash };
    } catch (e) {
      dispatch(trackEvent({
        eventType: 'order_creation_file_upload',
        property: { result: 'error', error: (e as Error)?.message, errorStack: (e as Error)?.stack },
      }));
      throw new ErrorWithOriginal(e, 'File upload error');
    } finally {
      setUploading(false);
    }
  }, [dispatch]);
  return {
    uploadFileServer,
    uploading,
    uploadFileStream,
  };
};
