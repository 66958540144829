import {
  memo, useCallback, useMemo, useContext, forwardRef,
} from 'react';
import { useField } from 'formik';
import cn from 'classnames';

import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { OfferSelected } from 'uikit/Offer/OfferSelected';
import { OfferSelect } from 'uikit/Offer/OfferSelect';
import { Box } from 'uikit/Box';
import { Tooltip } from 'uikit/Tooltip/Tooltip';
import { FileUploader } from 'uikit/FileUploader';
import { Spinner } from 'uikit/Spinner';
import { Notification } from 'uikit/Notification';
import { themeSelector } from 'lib/features/theme';
import { trackEvent } from 'lib/features/events/thunks';
import { FilesContext } from 'common/contexts/Files';
import classes from './OfferAdder.module.scss';
import { OfferAdderProps } from './types';
import { formats, MAX_SIZE, tooltip } from './helpers';
import { FileUploaderFileName } from './FileUploaderFileName';

export const OfferAdder = memo(forwardRef<HTMLDivElement, OfferAdderProps>(({
  title,
  onClickLink,
  onClickSelect: onClickSelectProps,
  className,
  values,
  onDelete,
  fileName,
  loading,
  isMultiple = false,
  addMultipleTitle,
  name,
  error: errorProp,
  validator,
}, ref) => {
  const dispatch = useAppDispatch();
  const theme = useAppSelector(themeSelector);
  const { files, onChangeFiles: onChangeFilesContext, onDeleteFile: onDeleteFileContext } = useContext(FilesContext);
  const [, { error }] = useField(name);
  const [
    ,
    { value: valueFile, error: errorFile },
    { setValue: setValueFile, setError: setErrorFile },
  ] = useField(fileName || 'DEFAULT_FILE_NAME');
  const onChangeFile = useCallback((file: File) => {
    setValueFile(file.name);
    onChangeFilesContext(file.name, file);
    dispatch(trackEvent({ eventType: 'order_form_file_upload', property: { result: 'success' } }));
  }, [onChangeFilesContext, setValueFile, dispatch]);
  const onDeleteFile = useCallback((id: string) => {
    setValueFile(null);
    onDeleteFileContext(id);
  }, [setValueFile, onDeleteFileContext]);
  const file = useMemo(() => files?.[valueFile], [valueFile, files]);
  const onErrorAddFile = useCallback((e: Error) => {
    const errorMsg = e?.message;
    setErrorFile(errorMsg);
    if (errorMsg) {
      dispatch(trackEvent({
        eventType: 'order_form_file_upload',
        property: { result: 'error', error: errorMsg, errorStack: e?.stack },
      }));
    }
  }, [setErrorFile, dispatch]);
  const onClickSelect = useCallback(() => {
    onClickSelectProps?.(name);
  }, [onClickSelectProps, name]);

  return (
    <Box direction="column" className={cn(classes[theme], className)} ref={ref}>
      <Box className={classes.title} data-testid={`title-${title.toLowerCase()}`}>{title}</Box>
      <Box direction="column" className={classes.content}>
        {loading && <Spinner fullscreen />}
        {!!fileName && !!file && (
          <FileUploaderFileName
            theme={theme}
            fileName={file.name}
            className={classes.mr}
            onDelete={onDeleteFile}
          />
        )}
        {!!values?.length && !(error || errorProp) && (
          <OfferSelected
            theme={theme}
            list={values}
            onDelete={onDelete}
            onClickName={onClickLink}
            className={classes.mr}
            dataTestId={name}
          />
        )}
        {(isMultiple || (!values?.length && (!fileName || !file))) && (
          <Box
            alignItems="center"
            justifyContent="flex-start"
            className={cn(classes.offersSelectWrap, classes.offersEmptyWrap)}
          >
            {!!fileName && !file && (
              <Tooltip
                tooltip={tooltip}
                theme={theme}
                classNamePopover={classes.popover}
                classNamePopoverText={classes.popoverText}
              >
                <FileUploader
                  theme={theme}
                  maxSize={MAX_SIZE}
                  formats={formats}
                  setFile={onChangeFile}
                  setError={onErrorAddFile}
                  validator={validator}
                />
              </Tooltip>
            )}
            {!!fileName && !file && <Box className={classes.or}>OR</Box>}
            <OfferSelect
              testId="file"
              theme={theme}
              onClickLink={onClickSelect}
              linkText={isMultiple && (values?.length || file) ? addMultipleTitle : 'Select from marketplace'}
            />
          </Box>
        )}
        {!!errorFile && <Notification theme={theme} className={classes.notification} variant="error">{errorFile}</Notification>}
        {!!error && <Notification theme={theme} className={classes.notification} variant="error">{error}</Notification>}
        {!!errorProp && <Notification theme={theme} className={classes.notification} variant="error">{errorProp}</Notification>}
      </Box>
    </Box>
  );
}));
