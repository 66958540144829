import { memo, FC } from 'react';
import cn from 'classnames';
import Link from 'next/link';

import { Theme } from 'uikit/common/types';
import { TabsLinkProps } from './types';
import classes from './TabsLink.module.scss';

export const TabsLink: FC<TabsLinkProps> = memo(({
  href, label, className, disabled, theme = Theme.dark, onClick, dataTestId,
}) => {
  return (
    <Link
      onClick={onClick}
      href={href}
      className={cn(classes.wrap, className, classes[theme], { [classes.disabled]: disabled })}
      data-testid={dataTestId}
    >
      {label}
    </Link>
  );
});