import dynamic from 'next/dynamic';

import { getOfferTextBtn, isOfferDisabled } from 'common/utils';
import { checkAddOffer } from 'lib/features/createOrder/helpers';
import { GetFieldsProps } from '../types';
import classes from '../../Content.module.scss';

const AdderBtnOffer = dynamic(() => import('../../../../AdderBtn/AdderBtnOffer/AdderBtnOffer'), { ssr: false });

export const getCommonFields = ({
  buildOrderForm, loading, onAddFormOffer, fieldBuildOrderForm, onDeleteFormOffer,
}: GetFieldsProps) => [
  {
    id: 'button',
    cell: (row) => {
      const { id, enabled, inactive } = row || {};
      const { isAdded } = checkAddOffer(id, buildOrderForm, fieldBuildOrderForm);
      return (
        <AdderBtnOffer
          disabled={loading}
          onAdd={() => onAddFormOffer({
            value: id,
            field: fieldBuildOrderForm,
            data: row,
          })}
          isAdded={isAdded}
          className={classes.adderBtn}
          onDelete={() => onDeleteFormOffer({
            field: fieldBuildOrderForm,
            value: id,
          })}
          offerTextBtn={getOfferTextBtn({ id, enabled, inactive })}
          dataTestId={`offer-${id}`}
        />
      );
    },
  },
  {
    id: 'disabled',
    cell: (row) => {
      const { id, enabled } = row || {};
      return isOfferDisabled(id, enabled);
    },
  },
];
