import { MutableRefObject, createRef } from 'react';
import * as Yup from 'yup';
import { FormikErrors } from 'formik/dist/types';

import {
  TeeOffer, Offer, TeeOfferSlot, OfferSlot, TeeOfferOption,
} from 'generated/types';
import {
  FieldsBuildOrderForm, BuildOrderForm, FormOffer, Slots,
} from 'lib/features/createOrder/types';
import { Files } from 'common/contexts/Files/types';
import { getLibarchivejs } from 'common/utils/libarchivejs';
import {
  getOfferSlotExtension, getOfferSlotLabelFormatted, getOfferSlotValueFormatted, OfferSlotsInfo, getDeposit,
} from 'common/utils/slots';
import { BalanceBigNumber } from 'lib/features/wallet/types';
import getConfig from 'config';
import { SLOT_COUNT } from 'lib/features/createOrder/helpers';
import { Item as ItemOfferSelected, SimpleItemSlots, SimpleItemSlotsInfoItem } from 'uikit/Offer/OfferSelected/types';
import {
  GetValidationSchemaProps, GetOffersListResult,
} from './types';
import classes from './BuildOrder.module.scss';

const errorMap = (onAutoSelect?: (isNeddRevalidated?: boolean) => Promise<void>) => ({
  [FieldsBuildOrderForm.solution]: 'A Solution offer is required',
  [FieldsBuildOrderForm.data]: 'At least one Data offer is required',
  [FieldsBuildOrderForm.tee]: (
    <div className={classes.text}>
      A Compute offer is required. You can use{' '}
      <button
        className={classes.link}
        onClick={() => onAutoSelect?.(true)}
      >
        auto-select
      </button>{' '}
      to add the minimum required configuration.
    </div>
  ),
  [FieldsBuildOrderForm.storage]: 'A Storage offer is required',
});

export const getDepositSchema = (balance?: BalanceBigNumber): Yup.BaseSchema => Yup.number()
  .required('required')
  .test(
    FieldsBuildOrderForm.deposit,
    'Not enough money on your wallet, please replenish the balance by clicking on the Get TEE button',
    (value) => {
      return !value || (!!balance?.tee && balance.tee?.isGreaterThanOrEqualTo(value));
    },
  );

const getOfferSchema = (
  field: FieldsBuildOrderForm,
  onAutoSelect?: (isNeddRevalidated?: boolean) => Promise<void>,
) => Yup.object().test(
  field,
  errorMap(onAutoSelect)[field],
  (item) => item?.value,
).required('required').nullable() as Yup.AnySchema<FormOffer>;

const getOfferDataSchema = (field: FieldsBuildOrderForm) => Yup.array().of(getOfferSchema(field)).test(
  field,
  errorMap[field],
  // eslint-disable-next-line func-names, prefer-arrow-callback
  function (arr) {
    if (this?.parent?.file) {
      return true;
    }
    return !!arr?.length;
  },
) as Yup.AnySchema<FormOffer>;

export const getValidationSchema = (props: GetValidationSchemaProps): Yup.AnySchema<BuildOrderForm> => {
  const { balanceBigNumber, onAutoSelect } = props || {};
  return Yup.object().shape({
    [FieldsBuildOrderForm.solution]: getOfferSchema(FieldsBuildOrderForm.solution),
    [FieldsBuildOrderForm.data]: getOfferDataSchema(FieldsBuildOrderForm.data) as Yup.AnySchema<FormOffer[]>,
    [FieldsBuildOrderForm.tee]: getOfferSchema(FieldsBuildOrderForm.tee, onAutoSelect),
    [FieldsBuildOrderForm.storage]: getOfferSchema(FieldsBuildOrderForm.storage),
    [FieldsBuildOrderForm.file]: Yup.mixed(),
    [FieldsBuildOrderForm.deposit]: getDepositSchema(balanceBigNumber),
  });
};

export const getOffersAdderSlotsInfo = (slots: OfferSlotsInfo[]): SimpleItemSlotsInfoItem[] => {
  if (!slots?.length) return [];
  const info = slots.reduce((acc, slot) => {
    Object.entries(slot).forEach(([key, value]) => {
      acc[key] = (acc[key] || 0) + value;
    });
    return acc;
  }, {} as OfferSlotsInfo);
  return Object.entries(info).reduce((acc, [key, value]) => {
    const formattedValue = getOfferSlotValueFormatted(key as keyof OfferSlotsInfo, value);
    const extension = getOfferSlotExtension(key as keyof OfferSlotsInfo, value);
    const formattedKey = getOfferSlotLabelFormatted(key as keyof OfferSlotsInfo);
    return [
      ...acc,
      {
        key: formattedKey,
        value: `${formattedValue} ${extension}`,
      }];
  }, [] as SimpleItemSlotsInfoItem[]);
};

export const getOffersTEEAdderSlots = (
  slots: Slots,
  slotsData: TeeOfferSlot[],
  optionsData: TeeOfferOption[],
  lease: number,
): SimpleItemSlots | null => {
  const { slot, options = [] } = slots || {};
  const { id, count } = slot || {};
  if (!id) return null;
  const slotsDataBySlotId = slotsData.find(({ id: slotId }) => slotId === id);
  const { info, usage } = slotsDataBySlotId || {};
  const {
    minTimeMinutes = 0, maxTimeMinutes = 0,
  } = usage || {};
  const { cpuCores = 0, diskUsage = 0, ram = 0 } = info || {};
  const slotsInfo: OfferSlotsInfo = {
    cpuCores,
    ram,
    diskUsage,
    bandwidth: 0,
    traffic: 0,
    externalPort: 0,
    maxTimeMinutes,
    minTimeMinutes,
  };
  const optionsDataByOptionsIds = options?.length
    ? optionsData.filter(({ id }) => options.find((option) => option?.id === id))
    : [];
  const depositSlots = getDeposit({
    slotUsage: usage, lease, count,
  });
  const optionsWithSlots = optionsDataByOptionsIds.reduce((acc, { info, id, usage }) => {
    const { bandwidth, externalPort, traffic } = info || {};
    const {
      minTimeMinutes = 0, maxTimeMinutes = 0,
    } = usage || {};
    const count = options?.find((option) => option?.id === id)?.count ?? 0;
    const data: OfferSlotsInfo = {
      cpuCores: 0,
      ram: 0,
      diskUsage: 0,
      bandwidth,
      traffic,
      externalPort,
      maxTimeMinutes,
      minTimeMinutes,
    };
    return {
      ...acc,
      info: [...acc.info, ...new Array(count).fill(data)],
      ...getDeposit({
        slotUsage: usage, deposit: { perHour: acc.perHour, fixed: acc.fixed, perHourByLease: acc.perHourByLease }, lease, count,
      }),
    };
  }, {
    info: [], ...depositSlots,
  } as SimpleItemSlots);
  return {
    ...optionsWithSlots,
    info: getOffersAdderSlotsInfo([...new Array(slots?.slot?.count).fill(slotsInfo), ...optionsWithSlots.info]),
  };
};

export const getOffersValueAdderSlots = (
  slots: Slots,
  slotsData: OfferSlot[],
  minTimeMinutesTEE,
  lease: number,
): SimpleItemSlots | null => {
  const { slot } = slots || {};
  const { id } = slot || {};
  if (!id) return null;
  const slotsDataBySlotId = slotsData.find(({ id: slotId }) => slotId === id);
  if (!slotsDataBySlotId) return null;
  const { info, usage, option } = slotsDataBySlotId;
  const {
    minTimeMinutes = 0, maxTimeMinutes = 0,
  } = usage || {};
  const { cpuCores = 0, diskUsage = 0, ram = 0 } = info || {};
  const { bandwidth = 0, externalPort = 0, traffic = 0 } = option || {};
  const data: OfferSlotsInfo = {
    cpuCores,
    ram,
    diskUsage,
    bandwidth,
    traffic,
    externalPort,
    maxTimeMinutes,
    minTimeMinutes,
  };
  const deposit = getDeposit({
    slotUsage: usage, lease, count: 1, minTimeMinutesTEE,
  });
  return {
    info: getOffersAdderSlotsInfo(new Array(SLOT_COUNT.PREVIEW).fill(data)),
    ...deposit,
  };
};

export const getOffersAdderSlots = (
  slots: Slots | undefined | null,
  dataSlots: (TeeOfferSlot | OfferSlot)[] | undefined,
  optionsData: TeeOfferOption[] | undefined,
  lease: number,
  minTimeMinutesSlotTEE?: number,
  isTEE = false,
): SimpleItemSlots | null => {
  if (!slots || !dataSlots?.length) return null;
  const slotsTEE = dataSlots as TeeOfferSlot[];
  const slotsValue = dataSlots as OfferSlot[];
  return isTEE
    ? getOffersTEEAdderSlots(slots, slotsTEE, optionsData || [], lease)
    : getOffersValueAdderSlots(slots, slotsValue, minTimeMinutesSlotTEE, lease);
};

export const getOffersAdderListItem = (value: FormOffer, lease: number, minTimeMinutesSlotTEE?: number): ItemOfferSelected[] => {
  if (!value || !value?.value) return [];
  const { data, slots } = (value || {});
  return [{
    id: value?.value || '',
    description: ((data as Offer)?.offerInfo || (data as TeeOffer)?.teeOfferInfo)?.name,
    subItems: value?.base ? getOfferList(value?.base, lease) : [],
    slots: getOffersAdderSlots(
      slots,
      data?.slots,
      (data as TeeOffer)?.options,
      lease,
      minTimeMinutesSlotTEE,
      !!(data as TeeOffer)?.teeOfferInfo,
    ),
  }];
};

export const getOfferList = (
  values: FormOffer | FormOffer[] | null,
  lease: number,
  minTimeMinutesSlotTEE?: number,
): ItemOfferSelected[] => {
  if (!values) return [];
  if (Array.isArray(values)) {
    return values.map((value) => getOffersAdderListItem(value, lease, minTimeMinutesSlotTEE)).flat();
  }
  return getOffersAdderListItem(values, lease, minTimeMinutesSlotTEE);
};

export const getOffersAdderList = (buildOrderForm: BuildOrderForm, lease: number): GetOffersListResult => {
  const tee = buildOrderForm[FieldsBuildOrderForm.tee];
  const { data, slots } = tee || {};
  const { slot } = slots || {};
  const { id: slotId } = slot || {};
  const { slots: dataSlots } = (data as TeeOffer) || {};
  const dataSlotByTEESelectedSlot = (dataSlots || []).find(({ id }) => slotId === id);
  const { usage } = dataSlotByTEESelectedSlot || {};
  const { minTimeMinutes: minTimeMinutesSlotTEE } = usage || {};
  return {
    [FieldsBuildOrderForm.solution]: getOfferList(buildOrderForm[FieldsBuildOrderForm.solution], lease, minTimeMinutesSlotTEE),
    [FieldsBuildOrderForm.tee]: getOfferList(tee, lease, minTimeMinutesSlotTEE),
    [FieldsBuildOrderForm.storage]: getOfferList(buildOrderForm[FieldsBuildOrderForm.storage], lease, minTimeMinutesSlotTEE),
    [FieldsBuildOrderForm.data]: getOfferList(buildOrderForm[FieldsBuildOrderForm.data], lease, minTimeMinutesSlotTEE),
  };
};

export const checkIndexHTML = async (buildOrderForm: BuildOrderForm, files?: Files): Promise<boolean> => {
  const offersForChecking = getConfig().NEXT_PUBLIC_OFFERS_CHECK_INDEX_HTML;
  const solutionOffer = buildOrderForm?.[FieldsBuildOrderForm.solution]?.value ?? '';
  const fileName = buildOrderForm?.[FieldsBuildOrderForm.file] ?? '';
  if (!Object.keys(offersForChecking)?.length || !files || !fileName || !solutionOffer) return true;
  const file = files?.[fileName];
  if (
    offersForChecking[solutionOffer]
    && file
  ) {
    const libarchivejs = await getLibarchivejs();
    const openedArchive = await libarchivejs.open(file);
    const filesInArchive = await openedArchive.extractFiles();
    const keys = Object.keys(filesInArchive);
    const fileCheckingName = 'index.html';
    if (filesInArchive?.[fileCheckingName] || keys.some((key) => filesInArchive?.[key]?.[fileCheckingName])) return true;
    throw new Error('index.html not found at the root.');
  }
  return true;
};

export const getPath = (key: string): string => {
  switch (key) {
    case FieldsBuildOrderForm.data:
      return '/data';
    case FieldsBuildOrderForm.tee:
      return '/compute';
    case FieldsBuildOrderForm.storage:
      return '/storage';
    case FieldsBuildOrderForm.solution:
      return '/solutions';
    default:
      return '';
  }
};

const orderElements = {
  [FieldsBuildOrderForm.solution]: 1,
  [FieldsBuildOrderForm.data]: 2,
  [FieldsBuildOrderForm.storage]: 3,
  [FieldsBuildOrderForm.tee]: 4,
  [FieldsBuildOrderForm.configurationError]: 5,
};

export const scrollToPosition = (resolve: FormikErrors<BuildOrderForm>, refs: MutableRefObject<{}>): void => {
  const keys = Object.keys(resolve);
  if (keys?.length) {
    const sorted = [...keys].sort((a, b) => orderElements[a] - orderElements[b]);
    refs.current[sorted[0]]?.current.scrollIntoView({
      behavior: 'smooth',
    });
  }
};

export const generateRefs: MutableRefObject<{}> = (
  Object.keys(orderElements).reduce((acc, field) => ({ ...acc, [field]: createRef() }), {} as MutableRefObject<{}>)
);
