import { memo, FC, useCallback } from 'react';
import { FiltersFields } from 'lib/features/filters/types';
// import { Providers } from '../common/Providers';
import { Compatible } from '../common/Compatible';
import { Offers } from '../common/Offers';
import { FiltersDataProps } from './types';

export const FiltersData: FC<FiltersDataProps> = memo(({
  name, onSubmit: onSubmitProps,
}) => {
  const onSubmit = useCallback(() => onSubmitProps(), [onSubmitProps]);
  return (
    <>
      <Compatible name={`${name}.${FiltersFields.compatible}`} title="Compatible" onChange={onSubmit} />
      {/* <Providers
        name={`${name}.${FiltersFields.providers}`}
        onChange={onSubmit}
      /> */}
      <Offers name={`${name}.${FiltersFields.offers}`} title="Offers" onChange={onSubmit} canShowBorder={false} />
    </>
  );
});