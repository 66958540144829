import dynamic from 'next/dynamic';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Box } from 'uikit/Box';
import { getOfferTextBtn } from 'common/utils';
import { Offer } from 'generated/types';
import { FieldsBuildOrderForm, BuildOrderForm } from 'lib/features/createOrder/types';
import { checkAddOffer } from 'lib/features/createOrder/helpers';
import { Theme } from 'uikit/common/types';
import { AddOfferToFormProps, DeleteOfferFromFormProps } from 'lib/features/createOrder/hooks/useAddOfferToForm';
import { Column } from '../../Table/types';

const columnHelper = createColumnHelper<Column>();

const AdderBtnOffer = dynamic(() => import('../../../../AdderBtn/AdderBtnOffer/AdderBtnOffer'), { ssr: false });

export interface GetColumnsProps {
  onAddFormOffer: (props: AddOfferToFormProps) => void;
  buildOrderForm: BuildOrderForm;
  onDeleteFormOffer: (props: DeleteOfferFromFormProps) => void;
  loading?: boolean;
  theme?: Theme;
}

export interface GetCommonColumnsProps extends GetColumnsProps {
  field: FieldsBuildOrderForm.data
  | FieldsBuildOrderForm.solution
  | FieldsBuildOrderForm.storage;
}

export const getCommonValueOfffersColumns = ({
  onAddFormOffer, buildOrderForm, loading, onDeleteFormOffer, field,
}: GetCommonColumnsProps): ColumnDef<Column>[] => [
  columnHelper.accessor('offerInfo', {
    header: '',
    id: 'icon',
    meta: {
      show: true,
    },
    size: 100,
    cell: (info) => {
      const original = info.row.original as Offer || {};
      const { id, enabled, inactive } = original;
      const { isAdded } = checkAddOffer(id, buildOrderForm, field);
      return (
        <Box justifyContent="flex-end">
          <AdderBtnOffer
            disabled={loading}
            onAdd={() => onAddFormOffer({ field, value: id, data: original })}
            isAdded={isAdded}
            onDelete={() => onDeleteFormOffer({
              field,
              value: id,
            })}
            offerTextBtn={getOfferTextBtn({ id, enabled, inactive })}
            dataTestId={`offer-${id}`}
          />
        </Box>
      );
    },
  }),
];
