import { Compatible } from 'lib/features/filters/types';

export const items = [
  {
    value: Compatible.all,
    label: 'Show all',
  }, {
    value: Compatible.compatible,
    label: 'Show compatible with order',
  },
];