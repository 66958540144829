import { memo, useMemo } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { useField } from 'formik';
import { Accordion } from 'uikit/Accordion';
import { getObjectCounter } from '../../common/helpers';
import classes from './Lease.module.scss';
import { FiltersFilterItem } from '../../common/FIltersFilterItem';
import { LeaseProps } from './types';

const InputFormik = dynamic(() => import('../../../../../../uikit/Input/InputFormik'), { ssr: false });

export const Lease = memo(({
  name, theme, onChange, canShowBorder,
}: LeaseProps) => {
  const [, { value: active }] = useField(name);
  const counter = useMemo(() => {
    if (!active) return undefined;
    return getObjectCounter(active) || undefined;
  }, [active]);

  return (
    <Accordion
      title="Lease"
      classNameCollapse={classes.accordionCollapse}
      classNameToggleWrap={classes.accordionToggleWrap}
      defaultOpen
      theme={theme}
      counter={counter}
      canShowBorder={canShowBorder}
    >
      <FiltersFilterItem
        label="Hours"
        className={classes.mb6}
      >
        <InputFormik
          isNumber
          isPositive
          name={`${name}.time`}
          classNameInput={cn(classes.input, classes[theme])}
          theme={theme}
          max={Number.MAX_SAFE_INTEGER}
          classNameWrap={classes.inputWrap}
          onChange={onChange}
        />
      </FiltersFilterItem>
      <div className={classes.spacer} />
    </Accordion>
  );
});