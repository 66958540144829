import {
  memo, FC, useMemo,
} from 'react';
import cn from 'classnames';

import { Box } from 'uikit/Box';
import { Stepper } from 'uikit/Stepper';
import { Modal } from 'uikit/Modal';
import { useAppSelector } from 'lib/hooks';
import { stepsSelector } from 'lib/features/createOrder';
import { Status, Steps } from 'lib/features/createOrder/types';
import { themeSelector } from 'lib/features/theme';
import { getList } from './helpers';
import { FormStepper } from './FormStepper';
import { CliLineBuilder } from './CliLineBuilder';
import classes from './CreateOrderModal.module.scss';
import { CreateOrderModalProps } from './types';

export const CreateOrderModal: FC<CreateOrderModalProps> = memo(({ show, onClose }) => {
  const steps = useAppSelector(stepsSelector);
  const theme = useAppSelector(themeSelector);
  const list = useMemo(() => getList(steps), [steps]);
  const active = useMemo(() => list.find(({ status }) => status === Status.ACTIVE), [list]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      bodyClassName={classes.body}
      contentClassName={classes.content}
      dialogClassName={classes.content}
      btnCloseClassName={classes.btnClose}
      size="lg"
      theme={theme}
    >
      <Box direction="row" className={cn(classes[theme], classes.wrap)}>
        <Box className={classes.stepperWrap} justifyContent="space-between" direction="column">
          <Stepper list={list} theme={theme} full />
          {active && active.value === Steps.BUILD_ORDER && <CliLineBuilder theme={theme} />}
        </Box>
        <FormStepper active={active?.value} onClickLink={onClose} className={classes.formStepperWrap} />
      </Box>
    </Modal>
  );
});