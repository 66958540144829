import {
  memo,
} from 'react';
import cn from 'classnames';
import { Theme } from 'uikit/common/types';
import { Box } from 'uikit/Box';
import { Accordion } from 'uikit/Accordion';
import { OfferSelectedItemProps } from './types';
import { OfferSelectedLine } from '../OfferSelectedLine';
import classes from './OfferSelectedItem.module.scss';
import { OfferSelectedItemHeader } from './OfferSelectedItemHeader';
import { OfferSelectedItemPrice } from './OfferSelectedItemPrice';
import {
  getOffersAdderSlotKeyFormatted, getOffersAdderSlotValueFormatted, getOffersAdderSlotExtension, getPrice,
} from './helpers';

export const OfferSelectedItem = memo(({
  description, className, onDelete, slots, id, theme = Theme.dark, onClickName, dataTestId = '',
}: OfferSelectedItemProps) => {
  return (
    <Box direction="column" className={cn(classes.content, classes[theme], className)} data-testid={`${dataTestId}-${id}`}>
      <OfferSelectedItemHeader
        id={id}
        theme={theme}
        onClickName={onClickName}
        dataTestId={dataTestId}
        onDelete={onDelete}
        description={description}
      />
      {!!slots?.info?.length && (
        <Accordion
          theme={theme}
          classNameToggleOpen={classes.accordionToggleOpen}
          classNameWrap={classes.accordionWrap}
          classNameToggleIconWrap={classes.accordionIconWrap}
          classNameToggleInner={classes.accordionToggleInner}
          title={(
            <OfferSelectedItemPrice
              priceFixed={getPrice(slots?.fixed)}
              pricePerHour={getPrice(slots?.perHour)}
            />
          )}
        >
          <Box className={classes.slots} direction="column">
            {slots.info.map(({
              key, value,
            }) => (
              <OfferSelectedLine
                {...{
                  className: classes.line,
                  key,
                  label: getOffersAdderSlotKeyFormatted(key),
                  value: getOffersAdderSlotValueFormatted(key, value),
                  extension: getOffersAdderSlotExtension(key),
                }}
              />
            ))}
          </Box>
        </Accordion>
      )}
    </Box>
  );
});
