import { memo } from 'react';
import cn from 'classnames';
import { Theme } from 'uikit/common/types';
import classes from './OfferSelect.module.scss';
import { defaultLinkText } from './helpers';
import { OfferSelectProps } from './types';

export const OfferSelect = memo(({
  onClickLink, className, linkText = defaultLinkText, theme = Theme.dark, testId, onKeyDown = () => {},
}: OfferSelectProps) => (
  <span
    data-test-id={testId}
    className={cn(classes.link, classes[theme], className)}
    onClick={onClickLink}
    onKeyDown={onKeyDown}
    role="textbox"
    tabIndex={0}
  >
    {linkText}
  </span>
));
