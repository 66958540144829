import { memo, useMemo, useCallback } from 'react';
import { LazyLoad } from 'uikit/LazyLoad';
import { OffersPages } from 'common/types/pages';
import { Theme } from 'uikit/common/types';
import { CardTemplateData } from '../Card/CardTemplateData';
import { CardTemplateCompute } from '../Card/CardTemplateCompute';
import { getCardFields } from './helpers';
import classes from './Cards.module.scss';
import { CardsProps } from './types';

export const Cards = memo(({
  fields,
  data,
  active,
  onClickCard,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  theme = Theme.dark,
}: CardsProps) => {
  const Template = useMemo(() => {
    switch (active) {
      case (OffersPages.compute):
      case (OffersPages.storage):
        return CardTemplateCompute;
      default:
        return CardTemplateData;
    }
  }, [active]);
  const onClick = useCallback((rowId, row) => {
    onClickCard(rowId, row);
  }, [onClickCard]);

  return (
    <LazyLoad
      className={classes.content}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      loading={isFetchingNextPage}
      showLoader
    >
      {data.map((row) => {
        const card = getCardFields(row, fields);
        return (
          <Template
            testId={row.id}
            key={row.id}
            card={card}
            onClick={() => onClick(row.id, row)}
            theme={theme}
          />
        );
      })}
    </LazyLoad>
  );
});

export default Cards;
