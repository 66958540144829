import { useCallback, useMemo } from 'react';
import Link from 'next/link';
import copy from 'copy-to-clipboard';

import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { buildOrderFormSelector } from 'lib/features/createOrder/selectors';
import { addSuccessNotification } from 'lib/features/notifications';
import { pageFiltersSelector } from 'lib/features/filters/selectors';
import { FormFiltersCompute } from 'lib/features/filters/types';
import { Box } from 'uikit/Box';
import { Button } from 'uikit/Buttons/Button';
import { Theme } from 'uikit/common/types';
import { OffersPages } from 'common/types/pages';
import { getFormattedString, href } from './helpers';
import { CliLineBuilderProps } from './types';
import classes from './CliLineBuilder.module.scss';

export const CliLineBuilder = ({ theme }: CliLineBuilderProps) => {
  const dispatch = useAppDispatch();
  const buildOrderForm = useAppSelector(buildOrderFormSelector);
  const { filters } = useAppSelector(pageFiltersSelector(OffersPages.compute));
  const lease = (filters as FormFiltersCompute)?.lease?.time ?? 0;
  const formattedString = useMemo(() => getFormattedString(buildOrderForm, lease), [buildOrderForm, lease]);
  const onClick = useCallback(() => {
    const base = './spctl workflows create';
    copy(`${base} ${formattedString.str}`);
    dispatch(addSuccessNotification('SPCTL command copied'));
  }, [formattedString, dispatch]);

  return (
    <Box className={classes.wrap} direction="column">
      <Button
        className={classes.btn}
        variant={theme === Theme.dark ? 'black' : 'white'}
        onClick={onClick}
        theme={theme}
        disabled={!formattedString.isComplete}
      >
        Copy CLI workflow
      </Button>
      <p className={classes.text}>
        You can copy the contents of this order as a&nbsp;
        <Link
          className={classes.link}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          workflows create
        </Link>&nbsp;
        SPCTL command
      </p>
    </Box>
  );
};
