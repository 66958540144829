import { useCallback, useState } from 'react';
import { OrderStatus } from '@super-protocol/sdk-js';
import { useLazyGetOrdersCountQuery } from 'lib/features/orders';
import { TOfferType } from 'generated/types';
import { selectedAddressSelector } from 'lib/features/wallet';
import { addErrorNotification } from 'lib/features/notifications';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import getConfig from 'config';

const { NEXT_PUBLIC_LIMIT_ORDERS_RUNNING } = getConfig();

export const useCheckOrdersLimit = () => {
  const dispatch = useAppDispatch();
  const selectedAddress = useAppSelector(selectedAddressSelector);
  const [loading, setLoading] = useState(false);
  const [getOrdersCount] = useLazyGetOrdersCountQuery();
  const getOrdersIds = useCallback(async () => {
    const response = await getOrdersCount({
      filter: {
        includeStatuses: [OrderStatus.Processing, OrderStatus.New],
        consumer: selectedAddress,
        offerType: TOfferType.TeeOffer,
      },
      pagination: { first: NEXT_PUBLIC_LIMIT_ORDERS_RUNNING + 10 },
    });
    const ids = (response?.data?.result?.page?.edges || [])
      .map((el) => el?.node?.id)
      .filter((id) => !!id);
    return ids;
  }, [getOrdersCount, selectedAddress]);
  const check = useCallback(async () => {
    try {
      setLoading(true);
      if (!selectedAddress) {
        throw new Error('Wallet is not connected');
      }
      const ids = await getOrdersIds();
      if (NEXT_PUBLIC_LIMIT_ORDERS_RUNNING && ids.length >= NEXT_PUBLIC_LIMIT_ORDERS_RUNNING) {
        const messageError = `We limit the maximum number of active orders per user.
        You will be able to create order when one of the ${ids.join(', ')} is completed.`;
        throw new Error(messageError);
      }
      return true;
    } catch (e) {
      dispatch(addErrorNotification((e as Error)?.message));
      return false;
    } finally {
      setLoading(false);
    }
  }, [dispatch, getOrdersIds, selectedAddress]);

  return {
    loading,
    check,
  };
};