import dynamic from 'next/dynamic';
import { GetFieldsProps } from './types';
import classes from '../Content.module.scss';
import { getCommonFields } from './common/fields';

const HtmlBox = dynamic(() => import('../../../../uikit/HtmlBox/HtmlBox'), { ssr: false });

export const getFields = ({
  buildOrderForm, loading, onAddFormOffer, fieldBuildOrderForm, theme, onDeleteFormOffer,
}: GetFieldsProps) => [
  {
    id: 'id',
    cell: (row) => {
      const { id } = row || {};
      return id;
    },
  },
  {
    id: 'name',
    cell: (row) => {
      const { offerInfo } = row || {};
      const { name } = offerInfo || {};
      return name;
    },
  },
  {
    id: 'provider',
    cell: (row) => {
      const { providerInfo } = row || {};
      const { name } = providerInfo || {};
      return name;
    },
  },
  {
    id: 'description',
    cell: (row) => {
      const { offerInfo } = row || {};
      const { description } = offerInfo || {};
      return description ? (
        <HtmlBox theme={theme} text={description} className={classes.description} ellipsis={false} />
      ) : '';
    },
  },
  ...getCommonFields({
    buildOrderForm, loading, onAddFormOffer, fieldBuildOrderForm, onDeleteFormOffer,
  }),
];
