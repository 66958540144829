import { Item } from 'uikit/Dropdown/types';
import { OffersPages } from 'common/types/pages';
import { Sorting } from './types';

export const getItems = (page?: OffersPages): Item[] => {
  switch (page) {
    case OffersPages.compute:
      return [
        { label: 'Availability', value: Sorting.availability },
        { label: 'Deposit', value: Sorting.price },
      ];
    default:
      return [
        { label: 'Newest', value: Sorting.newest },
      ];
  }
};