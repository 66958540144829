import BigNumber from 'bignumber.js';
import { getFixedDeposit } from 'common/utils/sdk';

export const getOffersAdderSlotKeyFormatted = (key: string): string => {
  const map = {
    cpuCores: 'vCores',
    ram: 'RAM',
    diskUsage: 'Disk',
    bandwidth: 'Bandwidth',
    traffic: 'Traffic',
    externalPort: 'Ext.Port',
    maxTimeMinutes: 'Min Time',
    minTimeMinutes: 'Max Time',
  };
  return map[key] || key;
};

export const getOffersAdderSlotValueFormatted = (key: string, value?: string | boolean | number): string => {
  const map = {
    externalPort: (value?: number) => (value ? 'Yes' : 'No'),
    ram: (value?: number) => (value ? value / 1000000 : 0),
  };
  return map[key]?.(value) || value;
};

export const getOffersAdderSlotExtension = (key: string): string => {
  const map = {
    ram: 'GB',
    diskUsage: 'GB',
    bandwidth: 'Mbps',
    traffic: 'Gb',
    maxTimeMinutes: 'hours',
    minTimeMinutes: 'hours',
  };
  return map[key] || '';
};

export const getPrice = (deposit: BigNumber | null): string => (deposit ? getFixedDeposit({ deposit }) : '');