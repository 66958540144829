import { Offers } from 'lib/features/filters/types';

export const items = [
  {
    value: Offers.sp,
    label: 'Super Protocol',
  }, {
    value: Offers.approved,
    label: 'Approved',
  }, {
    value: Offers.unmoderated,
    label: 'Unmoderated',
  }, {
    value: Offers.inactive,
    label: 'Inactive',
  },
];