import { memo, FC, useCallback } from 'react';
import cn from 'classnames';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { Button } from 'uikit/Buttons/Button';
import { FiltersFields } from 'lib/features/filters/types';
import { FieldsBuildOrderForm } from 'lib/features/createOrder/types';
import { Divider } from 'uikit/Divider';
import { Theme } from 'uikit/common/types';
import { useLazyGetMinimalConfigurationQuery } from 'generated/schemas/teeOffers';
import { getOffersWithSlotsIds } from 'lib/features/createOrder/helpers';
import { getFiltersFromMinimalConfiguration } from 'lib/features/filters/helpers';
import { OfferSlot, TeeOfferSlot } from 'generated/types';
import classes from './FiltersCompute.module.scss';
import { Options } from './Options';
import { Lease } from './Lease';
import { Slots } from './Slots';
// import { Availability } from './Availability';
import { FiltersComputeProps } from './types';

export const FiltersCompute: FC<FiltersComputeProps> = memo(({
  name, onSubmit: onSubmitProps, formOffers, onReset: onResetProps,
}) => {
  const [getMinimalConfiguration] = useLazyGetMinimalConfigurationQuery();
  const theme = useAppSelector(themeSelector);
  const onReset = useCallback(async () => {
    try {
      const MINUTES_IN_HOURS = 60;
      const response = await getMinimalConfiguration({ offers: getOffersWithSlotsIds(formOffers) });
      const filters = getFiltersFromMinimalConfiguration(response?.data?.result);
      const tee = formOffers?.[FieldsBuildOrderForm.tee];
      const minTimeMinutesFromSlot = ((tee?.data?.slots || []) as Array<OfferSlot | TeeOfferSlot>)
        .find(({ id }) => tee?.slots?.slot?.id === id)?.usage?.minTimeMinutes;
      const checkedLease = Math.max(
        filters?.[FiltersFields.lease]?.time ?? 0,
        Math.ceil((minTimeMinutesFromSlot ?? 0) / MINUTES_IN_HOURS),
      );
      onResetProps({
        ...filters,
        ...(checkedLease ? { [FiltersFields.lease]: { time: checkedLease } } : {}),
      });
    } catch (e) {
      console.error(e);
    }
  }, [onResetProps, getMinimalConfiguration, formOffers]);

  const onSubmit = useCallback(() => onSubmitProps(), [onSubmitProps]);

  return (
    <>
      <Button
        className={cn(classes.btn, classes.btnTop)}
        theme={theme}
        variant={theme === Theme.dark ? 'transparent' : 'white'}
        onClick={onReset}
        data-testid="button-reset-filters"
      >
        Reset to Order Compatible
      </Button>
      <Divider theme={theme} />
      <Slots
        name={`${name}.${FiltersFields.slots}`}
        theme={theme}
        onChange={onSubmit}
      />
      <Options
        name={`${name}.${FiltersFields.options}`}
        theme={theme}
        onChange={onSubmit}
      />
      <Lease
        name={`${name}.${FiltersFields.lease}`}
        theme={theme}
        onChange={onSubmit}
        canShowBorder={false}
      />
      {/* <Availability
        name={`${name}.${FiltersFields.availability}`}
        theme={theme}
        onChange={onSubmit}
      /> */}
    </>
  );
});