import React, { memo, FC } from 'react';
import cn from 'classnames';
import { Theme } from 'uikit/common/types';
import { ProgressBarProps } from './types';
import classes from './ProgressBar.module.scss';

const valuemax = 100;

export const ProgressBar: FC<ProgressBarProps> = memo(({ progress, className, theme = Theme.dark }) => {
  return (
    <div className={cn(classes.progress, className, classes[theme])}>
      <div
        className={cn(classes.progressBar, { [classes['progressBar-process']]: progress < valuemax })}
        style={{ width: `${progress}%` }}
        aria-valuemax={valuemax}
        aria-valuenow={progress}
        aria-valuemin={0}
      />
    </div>
  );
});
