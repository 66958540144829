import {
  memo, FC, useEffect, useMemo,
} from 'react';
import { useField } from 'formik';
import cn from 'classnames';

import { getFixedDeposit } from 'common/utils/sdk';
import { useAppDispatch } from 'lib/hooks';
import { updateDeposit } from 'lib/features/createOrder';
import { Box } from 'uikit/Box';
import { Divider } from 'uikit/Divider';
import { Notification } from 'uikit/Notification';
import { Spinner } from 'uikit/Spinner';
import { Theme } from 'uikit/common/types';
import { FieldsBuildOrderForm } from 'lib/features/createOrder/types';
import { DepositProps } from './types';
import { calcDeposit, calcPrice } from './helpers';
import classes from './Deposit.module.scss';

export const Deposit: FC<DepositProps> = memo(({
  formOffers, className, loading, minDeposit, lease, theme = Theme.dark,
}) => {
  const isSomeOffer = useMemo(() => Object.values(formOffers).some((formOffers) => formOffers?.length), [formOffers]);
  const dispatch = useAppDispatch();
  const [, { error }, { setValue }] = useField(FieldsBuildOrderForm.deposit);
  const price = useMemo(() => calcPrice(formOffers), [formOffers]);
  const deposit = useMemo(
    () => calcDeposit(price, isSomeOffer ? minDeposit : '0', lease),
    [price, minDeposit, isSomeOffer, lease],
  );

  useEffect(() => {
    const value = (deposit || 0).toString();
    setValue(value);
    dispatch(updateDeposit(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deposit, dispatch]);

  return (
    <Box className={cn(classes.wrap, className, classes[theme])} direction="column">
      <Box direction="column">
        <Box justifyContent="space-between">
          <span className={classes.label}>Fixed:</span>
          <span>{`${getFixedDeposit({ deposit: price.fixed })} TEE`}</span>
        </Box>
        <Box justifyContent="space-between" alignItems="center">
          <span className={classes.label}>Per Hour: </span>
          <span className={classes.perHour}>{`${getFixedDeposit({ deposit: price.perHour })} TEE`}</span>
        </Box>
        <Box justifyContent="space-between" alignItems="center">
          <span className={classes.label}>Lease: </span>
          <span className={classes.perHour}>{`${lease} Hours`}</span>
        </Box>
      </Box>
      <Divider className={classes.divider} />
      <Box justifyContent="space-between" className={classes.content}>
        <Box className={classes.left}>
          <span className={classes.txt}>Order Total</span>
        </Box>
        <Box className={classes.right} alignItems="center">
          {loading
            ? <Spinner className={classes.value} />
            : <span className={cn(classes.txt, classes.value)} data-testid="span-deposit">{getFixedDeposit({ deposit })}</span> }
          <span className={classes.txt}>TEE</span>
        </Box>
      </Box>
      {!!error && <Notification className={classes.error} variant="error">{error}</Notification>}
    </Box>
  );
});