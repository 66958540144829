import { useMemo } from 'react';
import {
  BuildOrderForm,
} from 'lib/features/createOrder/types';
import { useExtendedFormOffers } from './useExtendedFormOffers';

export const useExtendedBuildOrderForm = (buildOrderForm: BuildOrderForm) => {
  const { loading, formOffers } = useExtendedFormOffers(buildOrderForm);
  const extendedBuildOrderForm = useMemo(
    () => ({
      ...buildOrderForm,
      ...formOffers,
    }),
    [buildOrderForm, formOffers],
  );

  return {
    loading,
    extendedBuildOrderForm,
  };
};