import {
  memo, FC, useState, useMemo, useCallback, useContext, useRef, MutableRefObject, useEffect,
} from 'react';
import {
  Formik, FormikErrors, FormikHelpers, FormikProps,
} from 'formik';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { Button } from 'uikit/Buttons/Button';
import { Box } from 'uikit/Box';
import { Spinner } from 'uikit/Spinner';
import { useAppDispatch, useAppSelector } from 'lib/hooks';
import { ConnectWallet } from 'components/ConnectWallet';
import {
  BuildOrderForm, FieldsBuildOrderForm, Steps, FormOfferKey, Slots,
} from 'lib/features/createOrder/types';
import { buildOrderFormSelector } from 'lib/features/createOrder/selectors';
import {
  goNextStep as goNextStepAction,
  updateFormByStep as updateFormByStepAction, deleteOfferFromForm as deleteOfferFromFormActions,
} from 'lib/features/createOrder';
import { themeSelector } from 'lib/features/theme';
import {
  errorIsDataInFormOffers,
  getWorkflowConfigurationValidationFromBuildOrderForm,
} from 'lib/features/createOrder/helpers';
import { useExtendedBuildOrderForm } from 'lib/features/createOrder/hooks/useExtendedBuildOrderForm';
import { useLazyValidateConfiguraionQuery } from 'generated/schemas/orders';
import { isConnectedSelector } from 'lib/features/wallet';
import { balanceBigNumberSelector } from 'lib/features/wallet/selectors';
import { pageFiltersSelector } from 'lib/features/filters/selectors';
import { minDepositSelector } from 'lib/features/sdk';
import { FormFiltersCompute } from 'lib/features/filters/types';
import { OffersPages } from 'common/types/pages';
import { FilesContext } from 'common/contexts/Files';
import { useOfferModal } from 'common/hooks/useOfferModal';
import { getMinDeposit } from 'lib/features/sdk/thunks';
import { sleep } from 'common/utils';
import { BuildOrderProps } from './types';
import { OfferAdder } from './OfferAdder';
import classes from './BuildOrder.module.scss';
import {
  getValidationSchema, getOffersAdderList, checkIndexHTML, getPath, scrollToPosition, generateRefs,
} from './helpers';
import { Deposit } from './Deposit';
import { ConfigurationError } from './ConfigurationError';
import { FormikPersistRedux } from '../FormikPersistRedux';
import { ConfigurationErrors } from './ConfigurationError/types';
import { useAutoSelectTEE } from '../hooks/useAutoSelectTEE';
import { Header } from '../Header';

export const BuildOrder: FC<BuildOrderProps> = memo(({ onClickLink: onClickLinkProps }) => {
  const { autoSelectTEE, attentionModal, loading: loadingAutoSelectTEE } = useAutoSelectTEE();
  const [validateConfiguration] = useLazyValidateConfiguraionQuery();
  const { push } = useRouter();
  const theme = useAppSelector(themeSelector);
  const isConnected = useAppSelector(isConnectedSelector);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingDeposit, setLoadingDeposit] = useState(false);
  const dispatch = useAppDispatch();
  const buildOrderForm = useAppSelector(buildOrderFormSelector);
  const balanceBigNumber = useAppSelector(balanceBigNumberSelector);
  const { filters } = useAppSelector(pageFiltersSelector(OffersPages.compute));
  const minDeposit = useAppSelector(minDepositSelector);
  const [isValidating, setIsValidating] = useState(false);
  const { files } = useContext(FilesContext);
  const formikRef = useRef<FormikProps<BuildOrderForm>>(null);
  const lease = (filters as FormFiltersCompute)?.lease?.time ?? 0;
  const { loading, extendedBuildOrderForm } = useExtendedBuildOrderForm(buildOrderForm);
  const onAutoSelect = useCallback(async (isNeedRevalidated = false) => {
    await autoSelectTEE();
    if (isNeedRevalidated) {
      await sleep(1);
      formikRef?.current?.validateField(FieldsBuildOrderForm.tee);
    }
  }, [autoSelectTEE]);
  const validationSchema = useMemo(() => (
    getValidationSchema({ balanceBigNumber, onAutoSelect })
  ), [balanceBigNumber, onAutoSelect]);
  const refs = useRef<MutableRefObject<{}>>(generateRefs);
  const errorRef = useRef({});
  const onSubmit = useCallback((
    submitForm: () => Promise<void>,
    validateForm: (values?: any) => Promise<FormikErrors<BuildOrderForm>>,
  ) => async () => {
    setIsValidating(true);
    validateForm().then((resolve) => {
      if (resolve && Object.keys(resolve).length) {
        scrollToPosition(resolve, refs);
      } else {
        submitForm();
      }
    });
  }, []);
  const goNextStep = useCallback(() => {
    dispatch(goNextStepAction());
  }, [dispatch]);
  const offers = useMemo(() => getOffersAdderList(extendedBuildOrderForm, lease), [extendedBuildOrderForm, lease]);
  const validateWorkflow = useCallback(async (values: BuildOrderForm, formikHelpers: FormikHelpers<BuildOrderForm>) => {
    try {
      const MINUTES_IN_HOURS = 60;
      const response = await validateConfiguration({
        input: getWorkflowConfigurationValidationFromBuildOrderForm(values, (lease || 0) * MINUTES_IN_HOURS),
      });
      const { data, error } = response || {};
      const { result } = data || {};
      const { success = false, errors } = result || {};
      if (success) return;
      if (errors) {
        throw new Error(ConfigurationErrors.CONFIGURATION_AUTO_SELECT);
      }
      // todo ClientError combine message and stackrace. hack. fix it later
      const errorMessage = error?.message?.replace(/:\s{.*/gi, '');
      //
      if (errorMessage) {
        throw new Error(errorMessage);
      }
      throw new Error(ConfigurationErrors.UNKNOWN_ERROR);
    } catch (e) {
      formikHelpers.setFieldError(FieldsBuildOrderForm.configurationError, (e as Error)?.message);
      errorRef.current = { ...errorRef.current, configurationError: '' };
      throw e;
    }
  }, [validateConfiguration, lease]);
  const validateIndexHTML = useCallback(async (values: BuildOrderForm, formikHelpers: FormikHelpers<BuildOrderForm>) => {
    try {
      await checkIndexHTML(values, files);
    } catch (e) {
      formikHelpers.setFieldError(FieldsBuildOrderForm.file, (e as Error)?.message);
      errorRef.current = { ...errorRef.current, data: '' };
      throw e;
    }
  }, [files]);
  const onSubmitForm = useCallback(async (values: BuildOrderForm, formikHelpers: FormikHelpers<BuildOrderForm>) => {
    try {
      setIsValidating(true);
      setIsSubmitting(true);
      await validateIndexHTML(values, formikHelpers);
      await validateWorkflow(values, formikHelpers);
      goNextStep();
    } catch (e) {
      await sleep(0);
      scrollToPosition(errorRef.current, refs);
      errorRef.current = {};
      console.warn(e);
    } finally {
      setIsSubmitting(false);
    }
  }, [goNextStep, validateWorkflow, validateIndexHTML]);
  const onLoadingDeposit = useCallback((loading: boolean) => {
    setLoadingDeposit(loading);
  }, []);
  const onDelete = useCallback((props: { key: FormOfferKey, value?: string }) => {
    dispatch(deleteOfferFromFormActions(props));
  }, [dispatch]);
  const onDeleteSolution = useCallback(() => {
    onDelete({ key: FieldsBuildOrderForm.solution });
  }, [onDelete]);
  const onDeleteStorage = useCallback((value: string) => {
    onDelete({ key: FieldsBuildOrderForm.storage, value });
  }, [onDelete]);
  const onDeleteTee = useCallback((value: string) => {
    onDelete({ key: FieldsBuildOrderForm.tee, value });
  }, [onDelete]);
  const onDeleteData = useCallback((value: string) => {
    onDelete({ key: FieldsBuildOrderForm.data, value });
  }, [onDelete]);
  const someLoading = loading || loadingDeposit || isSubmitting;
  const {
    tee,
    solution,
    data,
    storage,
  } = offers;
  const {
    tee: teeError,
    solution: solutionError,
    storage: storageError,
    data: dataError,
  } = useMemo(() => errorIsDataInFormOffers(extendedBuildOrderForm), [extendedBuildOrderForm]);
  const someError = !!(teeError || solutionError || storageError || dataError);
  const { openModal } = useOfferModal();
  const onClickLink = useCallback((offerId?: string, slots?: Slots | null) => {
    openModal(offerId, slots);
    onClickLinkProps?.();
  }, [onClickLinkProps, openModal]);
  const onClickLinkTee = useCallback((offerId?: string) => {
    onClickLink(offerId, buildOrderForm?.[FieldsBuildOrderForm.tee]?.slots);
  }, [onClickLink, buildOrderForm]);
  const onClickSelect = useCallback((key: string) => {
    const path = getPath(key);
    if (path) {
      push(path, undefined, { shallow: true });
    }
    onClickLinkProps?.();
  }, [push, onClickLinkProps]);

  useEffect(() => {
    dispatch(getMinDeposit());
  }, [dispatch]);

  return (
    <Formik<BuildOrderForm>
      validateOnChange={isValidating}
      validateOnBlur={isValidating}
      initialValues={buildOrderForm}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmitForm}
      innerRef={formikRef}
    >
      {({ submitForm, values, validateForm }) => {
        return (
          <Box direction="column" className={cn(classes.builder, classes[theme])}>
            <Header className={classes.header} />
            {attentionModal}
            {loadingAutoSelectTEE && <Spinner fullscreen classNameWrap={classes.spinner} />}
            <FormikPersistRedux<BuildOrderForm>
              action={updateFormByStepAction}
              step={Steps.BUILD_ORDER}
              values={values}
            />
            <Box direction="column" justifyContent="flex-start" className={classes.content}>
              <OfferAdder
                loading={loading}
                title="Solution"
                onClickLink={onClickLink}
                onClickSelect={onClickSelect}
                onDelete={onDeleteSolution}
                className={classes.item}
                values={solution}
                name={FieldsBuildOrderForm.solution}
                error={solutionError}
                ref={refs.current[FieldsBuildOrderForm.solution]}
              />
              <OfferAdder
                loading={loading}
                title="Data"
                onClickLink={onClickLink}
                onDelete={onDeleteData}
                onClickSelect={onClickSelect}
                className={classes.item}
                values={data}
                fileName={FieldsBuildOrderForm.file}
                isMultiple
                addMultipleTitle="Add another dataset"
                name={FieldsBuildOrderForm.data}
                error={dataError}
                ref={refs.current[FieldsBuildOrderForm.data]}
              />
              <OfferAdder
                loading={loading}
                title="Storage"
                onClickLink={onClickLink}
                onClickSelect={onClickSelect}
                onDelete={onDeleteStorage}
                className={classes.item}
                values={storage}
                name={FieldsBuildOrderForm.storage}
                error={storageError}
                ref={refs.current[FieldsBuildOrderForm.storage]}
              />
              <OfferAdder
                loading={loading}
                title="Compute"
                onClickLink={onClickLinkTee}
                onClickSelect={onClickSelect}
                onDelete={onDeleteTee}
                className={classes.configurationError}
                values={tee}
                name={FieldsBuildOrderForm.tee}
                error={teeError}
                ref={refs.current[FieldsBuildOrderForm.tee]}
              />
            </Box>
            <ConfigurationError
              name={FieldsBuildOrderForm.configurationError}
              className={classes.configurationError}
              ref={refs.current[FieldsBuildOrderForm.configurationError]}
              onAutoSelect={onAutoSelect}
            />
            <Box direction="column" className={cn(classes.checkout, classes[theme])}>
              <Deposit
                theme={theme}
                className={classes.deposit}
                formOffers={offers}
                onLoading={onLoadingDeposit}
                loading={loadingDeposit}
                minDeposit={minDeposit as string}
                lease={lease}
              />
              <Box justifyContent="flex-end">
                {
                  isConnected
                    ? (
                      <Button
                        theme={theme}
                        onClick={onSubmit(submitForm, validateForm)}
                        disabled={someLoading || someError}
                        data-testid="button-checkout"
                      >
                        Checkout
                      </Button>
                    )
                    : <ConnectWallet />
                }
              </Box>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
});
