import { memo, FC, useMemo } from 'react';
import cn from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

import { OffersPages } from 'common/types/pages';
import { Box } from 'uikit/Box';
import { FiltersCompute } from './FiltersCompute';
import { FiltersSolution } from './FiltersSolution';
import { FiltersStorage } from './FiltersStorage';
import { FiltersData } from './FiltersData';
import { FiltersProps } from './types';
import classes from './Filters.module.scss';

export const Filters: FC<FiltersProps> = memo(({
  className, name, onSubmit, active, interval = 100, formOffers,
}) => {
  const submitDebounced = useDebouncedCallback(onSubmit, interval);
  const renderFilters = useMemo(() => {
    if (!active) return null;

    const filters = {
      [OffersPages.compute]: (
        <FiltersCompute {...{
          name, onSubmit: submitDebounced, formOffers, onReset: onSubmit,
        }}
        />
      ),
      [OffersPages.storage]: (
        <FiltersStorage {...{
          name, onSubmit: submitDebounced,
        }}
        />
      ),
      [OffersPages.data]: (
        <FiltersData {...{
          name, onSubmit: submitDebounced,
        }}
        />
      ),
      [OffersPages.solutions]: (
        <FiltersSolution {...{
          name, onSubmit: submitDebounced,
        }}
        />
      ),
    };

    return filters[active];
  }, [name, submitDebounced, active, formOffers, onSubmit]);

  return (
    <Box className={cn(classes.wrap, className)} direction="column">
      {renderFilters}
    </Box>
  );
});