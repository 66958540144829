import { memo, FC, useMemo } from 'react';
import Image from 'next/image';
import { Box } from 'uikit/Box';
import { Theme } from 'uikit/common/types';
import { FileUploaderFileNameDescriptionProps } from './types';
import classes from './FileUploaderFileNameDescription.module.scss';

export const FileUploaderFileNameDescription: FC<FileUploaderFileNameDescriptionProps> = memo(({
  fileName,
  theme = Theme.dark,
}) => {
  const src = useMemo(() => `/icons/file_${theme}.png`, [theme]);
  return (
    <Box alignItems="center" className={classes[theme]}>
      <Box className={classes.icon}>
        <Image src={src} alt="file" width={16} height={16} />
      </Box>
      <span>{fileName}</span>
    </Box>
  );
});