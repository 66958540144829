import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { TooltipDeposit } from 'common/components';
import {
  OfferInfo, ProviderInfo, Offer, PriceType,
} from 'generated/types';
import { FieldsBuildOrderForm } from 'lib/features/createOrder/types';
import { Column } from '../Table/types';
import { getSlotPrice } from './helpers';
import { GetColumnsProps, getCommonValueOfffersColumns } from './common/columns';

const columnHelper = createColumnHelper<Column>();

export const getColumns = ({
  onAddFormOffer, buildOrderForm, loading, onDeleteFormOffer,
}: GetColumnsProps): ColumnDef<Column>[] => [
  columnHelper.accessor('id', {
    header: 'ID',
    id: 'id',
    size: 82,
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('offerInfo', {
    id: 'name',
    header: 'Name',
    size: 180,
    cell: (info) => {
      const { name } = info.getValue() as OfferInfo || {};
      return name;
    },
  }),
  columnHelper.accessor('providerInfo', {
    id: 'provider',
    header: 'Provider',
    size: 150,
    cell: (info) => {
      const { name } = info.getValue() as ProviderInfo || {};
      return name;
    },
  }),
  columnHelper.accessor('slots', {
    id: 'priceHr',
    header: 'Price, hr',
    cell: (info) => {
      const original = info.row.original as Offer || {};
      const price = getSlotPrice(original, PriceType.PerHour, buildOrderForm, FieldsBuildOrderForm.storage);
      return price
        ? (
          <TooltipDeposit deposit={price} currency="TEE" tooltip={false} />
        ) : '-';
    },
  }),
  columnHelper.accessor('slots', {
    id: 'priceFixed',
    header: 'Price, fix',
    cell: (info) => {
      const original = info.row.original as Offer || {};
      const price = getSlotPrice(original, PriceType.Fixed, buildOrderForm, FieldsBuildOrderForm.storage);
      return price
        ? (
          <TooltipDeposit deposit={price} currency="TEE" tooltip={false} />
        ) : '-';
    },
  }),
  ...getCommonValueOfffersColumns({
    onAddFormOffer, buildOrderForm, loading, onDeleteFormOffer, field: FieldsBuildOrderForm.storage,
  }),
];
