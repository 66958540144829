import {
  memo, FC, useCallback,
} from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { OffersPages } from 'common/types/pages';
import { Box } from 'uikit/Box';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { isMobileSelector } from 'lib/features/windowSize';
import { PageCounter } from 'common/components/PageCounter';
import { updateOffersViewPage, offerViewContolPanelSelector } from 'lib/features/controlPanel';
import { ViewType } from 'lib/features/controlPanel/types';
import classes from './ControlPanel.module.scss';
import { Sorting } from './Sorting';
// import { Search } from './Search';
import { ControlPanelProps, ControlPanelFields } from './types';

const TableConfigurator = dynamic(
  () => import('../../../../common/components/TableConfigurator/TableConfigurator'),
  { ssr: false },
);

const Switcher = dynamic(
  () => import('./Switcher/Switcher'),
  { ssr: false },
);

export const ControlPanel: FC<ControlPanelProps> = memo(({
  columns, name, active, onSubmit, showCount, totalCount, interval = 100,
}) => {
  const isMobile = useAppSelector(isMobileSelector);
  const dispatch = useAppDispatch();
  const offerView = useAppSelector(offerViewContolPanelSelector);
  const viewType = offerView?.[active];
  const onClick = useCallback(() => {
    dispatch(updateOffersViewPage({ viewType: viewType === ViewType.table ? ViewType.cards : ViewType.table, active }));
  }, [active, dispatch, viewType]);

  return (
    <Box className={classes.wrap} alignItems="center" justifyContent="space-between">
      {/* <Search /> */}
      <PageCounter showCount={showCount} totalCount={totalCount} label="offers" />
      <Box>
        <Sorting
          className={classes.mr}
          name={`${name}.${ControlPanelFields.sorting}`}
          onSubmit={onSubmit}
          interval={interval}
          page={active}
        />
        {![OffersPages.compute, OffersPages.storage].includes(active) && (
          <Switcher className={cn(classes.switcher, classes.mr)} onClick={onClick} viewType={viewType} />
        )}
        {offerView?.[active] === 'table' && !isMobile && (
          <TableConfigurator
            className={classes.mr}
            classNameDropdownToggle={classes.dropdownToggle}
            columns={columns}
            table={active}
          />
        )}
      </Box>
    </Box>
  );
});