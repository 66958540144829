import {
  FormOffers, FormOffer, Slot, Option,
} from 'lib/features/createOrder/types';
import { TOfferType, Order } from 'generated/types';

export interface AnalyticsOffer {
  offer?: string;
  slot?: Slot | null;
  options?: Option[] | null;
  offerType?: TOfferType;
}

export const getAnalyticsOffer = (formOffer: FormOffer | null, offerType: TOfferType): AnalyticsOffer[] => {
  if (!formOffer) return [];
  const { base, value, slots } = formOffer;
  const { slot = null, options = null } = slots || {};
  return [{
    offer: value, slot, options, offerType,
  }, ...(base || [])
    .map((formOffer) => getAnalyticsOffer(formOffer, offerType)).flat()];
};

export const getAnalyticsOffersFromFormOffers = (formOffers: FormOffers): AnalyticsOffer[] => {
  const {
    solution, tee, storage, data,
  } = formOffers || {};
  return [
    ...getAnalyticsOffer(solution, TOfferType.Solution),
    ...getAnalyticsOffer(tee, TOfferType.TeeOffer),
    ...getAnalyticsOffer(storage, TOfferType.Storage),
    ...(data || []).map((f) => getAnalyticsOffer(f, TOfferType.Data)).flat(),
  ];
};

export const getAnalyticsOffersFromOrder = (order?: Order): AnalyticsOffer[] => {
  if (!order) return [];
  const {
    subOrders, offerType, orderInfo, selectedUsage,
  } = order;
  const { offerId } = orderInfo || {};
  const { optionIds, optionsCount } = selectedUsage || {};
  return [
    {
      offer: offerId,
      offerType: offerType as TOfferType,
      options: (optionIds || []).map((id, index) => ({ id, count: optionsCount?.[index] ?? 0 })),
    },
    ...(subOrders || []).map((order) => getAnalyticsOffersFromOrder(order as Order)).flat(),
  ];
};