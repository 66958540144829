import { memo, FC } from 'react';
import cn from 'classnames';
import { Box } from 'uikit/Box';
import { Icon } from 'uikit/Icon';
import { Theme } from 'uikit/common/types';
import { CounterProps, Status } from './types';
import classes from './Counter.module.scss';

export const Counter: FC<CounterProps> = memo(({ count, status, theme = Theme.dark }) => {
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      className={cn(classes.wrap, classes[theme], classes[status])}
    >
      {status === Status.COMPLETED ? <Icon name="check_small" className={classes.icon} width={10} height={10} /> : count}
    </Box>
  );
});