import {
  memo, FC, useCallback, useMemo,
} from 'react';
import cn from 'classnames';
import { Box } from 'uikit/Box';
import { Icon } from 'uikit/Icon';
import classes from './OfferSelecteditemHeader.module.scss';
import { OfferSelectedItemHeaderProps } from './types';

export const OfferSelectedItemHeader: FC<OfferSelectedItemHeaderProps> = memo(({
  onClickName: onClickNameProp,
  id,
  description,
  onDelete,
  dataTestId,
  theme,
  className,
}) => {
  const descriptionFormatted = useMemo(() => {
    if (description instanceof File) {
      return description?.name || '';
    }
    return description;
  }, [description]);

  const onClickDelete = useCallback(() => {
    onDelete?.(id);
  }, [onDelete, id]);

  const onClickName = useCallback(() => {
    onClickNameProp?.(id);
  }, [onClickNameProp, id]);

  return (
    <Box alignItems="center" justifyContent="space-between" className={cn(classes[theme], className)}>
      {onClickNameProp ? (
        <button
          onClick={onClickName}
          className={classes.description}
        >
          {descriptionFormatted}
        </button>
      ) : <div className={classes.description}>{descriptionFormatted}</div>}
      {onDelete && (
        <Box
          className={classes.iconWrapper}
          justifyContent="center"
          alignItems="center"
          onClick={onClickDelete}
          data-testid={`${dataTestId}-${id}-trash`}
        >
          <Icon name="trash" width={9} height={11} />
        </Box>
      )}
    </Box>
  );
});