import {
  memo,
  FC,
  useMemo,
  cloneElement,
  ReactNode,
  useCallback,
} from 'react';
import cn from 'classnames';
import { Theme } from 'uikit/common/types';
import { Box } from 'uikit/Box';
import { Icon } from 'uikit/Icon';
import { CircleCounter } from 'uikit/CircleCounter';
import { AccordionToggleProps } from './types';
import classes from './AccordionToggle.module.scss';

export const AccordionToggle: FC<AccordionToggleProps> = memo(({
  onSelect,
  isOpen,
  title,
  icon,
  theme = Theme.dark,
  dataTestId = '',
  counter,
  classNameWrap,
  classNameIconWrap,
  classNameOpen,
  classNameInner,
}) => {
  const onClick = useCallback(() => onSelect(!isOpen), [onSelect, isOpen]);

  const iconWithFill: ReactNode = useMemo(
    () => !!icon && cloneElement(
      icon,
      {
        className: cn(classes.icon, icon?.props?.className),
        'data-testid': `${dataTestId ? `${dataTestId}-` : dataTestId}accordion-toggle-icon`,
      },
    ),
    [icon, dataTestId],
  );

  const dataTestIdIcon = useMemo(
    () => {
      const hasdataTestId = dataTestId ? `${dataTestId}-` : dataTestId;
      return `${hasdataTestId}accordion-toggle-${isOpen ? 'defis' : 'plus'}`;
    },
    [isOpen, dataTestId],
  );

  return (
    <button
      type="button"
      onClick={onClick}
      data-testid={`accordion-toggle-btn${dataTestId ? `-${dataTestId}` : ''}`}
      className={cn(classes.btn, classes[theme], { [cn(classes.open, classNameOpen)]: isOpen }, classNameWrap)}
    >
      <div className={cn(classes.inner, classNameInner)}>
        <div className={classes.left}>
          {!!iconWithFill && <Box alignItems="center" className={classes.iconWrap}>{iconWithFill}</Box>}
          <div className={classes.title}>{title}</div>
        </div>
        <Box className={cn(classes.right, classNameIconWrap)} alignItems="center">
          {!isOpen && counter !== undefined && (
            <CircleCounter
              counter={counter}
              theme={theme}
              className={classes.counter}
              dataTestId={`accordion-counter-label${dataTestId ? `-${dataTestId}` : ''}`}
            />
          )}
          <Icon
            name="triangle"
            className={classes.icon}
            width={7}
            data-testid={dataTestIdIcon}
          />
        </Box>
      </div>
    </button>
  );
});
