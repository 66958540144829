import {
  memo, forwardRef, useMemo,
} from 'react';
import { useField } from 'formik';
import { Notification } from 'uikit/Notification';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { ConfigurationErrorProps, ConfigurationErrors } from './types';
import { UnknownError } from './UnknownError';
import { ConfigurationErrorAutoSelect } from './ConfigurationErrorAutoSelect';

export const ConfigurationError = memo(forwardRef<HTMLDivElement, ConfigurationErrorProps>(({
  name,
  className,
  onAutoSelect,
}, ref) => {
  const theme = useAppSelector(themeSelector);
  const [, { error }] = useField(name);
  const errorRender = useMemo(() => {
    switch (error) {
      case ConfigurationErrors.CONFIGURATION_AUTO_SELECT:
        return <ConfigurationErrorAutoSelect onAutoSelect={onAutoSelect} />;
      case ConfigurationErrors.UNKNOWN_ERROR:
        return <UnknownError />;
      default:
        return error;
    }
  }, [error, onAutoSelect]);
  if (!error) return null;
  return (
    <Notification theme={theme} variant="warning" className={className} ref={ref}>
      {errorRender}
    </Notification>
  );
}));
