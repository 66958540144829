import { Item as ItemStepper } from 'uikit/Stepper/types';
import { Steps, Item } from 'lib/features/createOrder/types';

export const getTitle = (step: Steps): string => {
  switch (step) {
    case Steps.BUILD_ORDER:
      return 'Build order';
    case Steps.CREATE_PASSPHRASE:
      return 'Create Passphrase';
    case Steps.CONFIRM_TRANSACTIONS:
      return 'Confirm Transactions';
    default:
      return '';
  }
};

export const getList = (steps: Item[]): ItemStepper<Steps>[] => (steps || []).map((props) => ({
  ...props, title: getTitle(props.value),
}));