import { memo, useMemo } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { useField } from 'formik';
import { Accordion } from 'uikit/Accordion';
import { getLabelWithExtenstion } from 'common/utils/slots';
import classes from './Slots.module.scss';
import { FiltersFilterItem } from '../../common/FIltersFilterItem';
import { getObjectCounter } from '../../common/helpers';
import { SlotsProps } from './types';

const InputFormik = dynamic(() => import('../../../../../../uikit/Input/InputFormik'), { ssr: false });

export const Slots = memo(({
  name, theme, onChange,
}: SlotsProps) => {
  const [, { value: active }] = useField(name);
  const counter = useMemo(() => {
    if (!active) return undefined;
    return getObjectCounter(active) || undefined;
  }, [active]);

  return (
    <Accordion
      title="Slots"
      classNameCollapse={classes.accordionCollapse}
      classNameToggleWrap={classes.accordionToggleWrap}
      defaultOpen
      theme={theme}
      counter={counter}
    >
      <FiltersFilterItem
        label={getLabelWithExtenstion('cpuCores')}
        className={classes.mb6}
      >
        <InputFormik
          isFloat
          isPositive
          name={`${name}.cpuCores`}
          classNameInput={cn(classes.input, classes[theme])}
          theme={theme}
          max={Number.MAX_SAFE_INTEGER}
          classNameWrap={classes.inputWrap}
          onKeyDown={onChange}
        />
      </FiltersFilterItem>
      <FiltersFilterItem
        label={getLabelWithExtenstion('ram')}
        className={classes.mb6}
      >
        <InputFormik
          isFloat
          isPositive
          name={`${name}.ram`}
          classNameInput={cn(classes.input, classes[theme])}
          theme={theme}
          max={Number.MAX_SAFE_INTEGER}
          classNameWrap={classes.inputWrap}
          onKeyDown={onChange}
        />
      </FiltersFilterItem>
      <FiltersFilterItem
        label={getLabelWithExtenstion('diskUsage')}
        className={classes.mb6}
      >
        <InputFormik
          isFloat
          isPositive
          name={`${name}.diskUsage`}
          classNameInput={cn(classes.input, classes[theme])}
          theme={theme}
          max={Number.MAX_SAFE_INTEGER}
          classNameWrap={classes.inputWrap}
          onKeyDown={onChange}
        />
      </FiltersFilterItem>
      <div className={classes.spacer} />
    </Accordion>
  );
});