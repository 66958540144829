import { memo } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { Theme } from 'uikit/common/types';
import { FileUploaderCoreProps } from './types';
import classes from './FileUploaderCore.module.scss';

export const FileUploaderCore = memo<FileUploaderCoreProps>(({
  children,
  options = {},
  error,
  className,
  theme = Theme.dark,
}) => {
  const { getRootProps, getInputProps } = useDropzone(options);

  return (
    <div
      {...getRootProps({ className: cn(classes.dropzone, classes[theme], { [classes['dropzone-error']]: error }, className) })}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  );
});
