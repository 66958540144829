import { memo, useMemo } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { useField } from 'formik';
import { Accordion } from 'uikit/Accordion';
import { Box } from 'uikit/Box';
import { RadioFormik } from 'uikit/Radio/RadioFormik';
import { getLabelWithExtenstion } from 'common/utils/slots';
import { getObjectCounter } from '../../common/helpers';
import classes from './Options.module.scss';
import { FiltersFilterItem } from '../../common/FIltersFilterItem';
import { OptionsProps } from './types';
import { externalPorts } from './helpers';

const InputFormik = dynamic(() => import('../../../../../../uikit/Input/InputFormik'), { ssr: false });

export const Options = memo(({
  name, theme, onChange,
}: OptionsProps) => {
  const [, { value: active }] = useField(name);
  const counter = useMemo(() => {
    if (!active) return undefined;
    return getObjectCounter(active) || undefined;
  }, [active]);

  return (
    <Accordion
      title="Options"
      classNameCollapse={classes.accordionCollapse}
      classNameToggleWrap={classes.accordionToggleWrap}
      defaultOpen
      theme={theme}
      counter={counter}
    >
      <FiltersFilterItem label={getLabelWithExtenstion('bandwidth')} className={classes.mb6}>
        <InputFormik
          isFloat
          isPositive
          name={`${name}.bandwidth`}
          classNameInput={cn(classes.input, classes[theme])}
          theme={theme}
          max={Number.MAX_SAFE_INTEGER}
          classNameWrap={classes.inputWrap}
          onChange={onChange}
        />
      </FiltersFilterItem>
      <FiltersFilterItem label={getLabelWithExtenstion('traffic')} className={classes.mb6}>
        <InputFormik
          isFloat
          isPositive
          name={`${name}.traffic`}
          classNameInput={cn(classes.input, classes[theme])}
          theme={theme}
          max={Number.MAX_SAFE_INTEGER}
          classNameWrap={classes.inputWrap}
          onChange={onChange}
        />
      </FiltersFilterItem>
      <FiltersFilterItem label={getLabelWithExtenstion('externalPort')} className={classes.mb6}>
        <Box className={classes.extports}>
          {externalPorts.map(({ value, label }) => (
            <RadioFormik
              key={value}
              name={`${name}.externalPort`}
              value={value}
              label={label}
              theme={theme}
              onChange={onChange}
              classNameContainer={classes.radio}
              classNameLabel={classes.radioLabel}
            />
          ))}
        </Box>
      </FiltersFilterItem>
      <div className={classes.spacer} />
    </Accordion>
  );
});