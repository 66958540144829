import * as Yup from 'yup';
import { CreatePassphraseForm, FieldsCreatePassphraseForm } from 'lib/features/createOrder/types';
import { Modes } from 'uikit/MnemonicGenerator/MnemonicGeneratorUi/types';
import { validateMnemonic } from 'common/utils/crypto';

export const getPhraseGeneratedSchema = (): Yup.BaseSchema => Yup.string().test(
  FieldsCreatePassphraseForm.phraseGenerated,
  'Invalid phrase',
  // eslint-disable-next-line func-names
  function (value) {
    return this.parent[FieldsCreatePassphraseForm.phraseMode] === Modes.generate ? validateMnemonic(value as string) : true;
  },
) as Yup.BaseSchema;

export const getPhraseInputSchema = (): Yup.BaseSchema => Yup.string().test(
  FieldsCreatePassphraseForm.phraseInput,
  'Invalid phrase entered',
  // eslint-disable-next-line func-names
  async function (value) {
    if (this.parent[FieldsCreatePassphraseForm.phraseMode] === Modes.own) {
      if (!value) return false;
      return validateMnemonic(value.trim());
    }
    return true;
  },
) as Yup.BaseSchema;

export const getValidationSchema = (): Yup.AnySchema<CreatePassphraseForm> => {
  return Yup.object({
    [FieldsCreatePassphraseForm.phraseGenerated]: getPhraseGeneratedSchema(),
    [FieldsCreatePassphraseForm.phraseInput]: getPhraseInputSchema(),
    [FieldsCreatePassphraseForm.phraseMode]: Yup.mixed<Modes>().oneOf(Object.values(Modes)),
    [FieldsCreatePassphraseForm.agreement]: Yup.boolean().oneOf([true], 'required').required('required'),
  });
};