import { memo, FC, useCallback } from 'react';
import { FiltersFields } from 'lib/features/filters/types';
// import { Providers } from '../common/Providers';
import { Offers } from '../common/Offers';
import { FiltersStorageProps } from './types';

export const FiltersStorage: FC<FiltersStorageProps> = memo(({
  name, onSubmit: onSubmitProps,
}) => {
  const onSubmit = useCallback(() => onSubmitProps(), [onSubmitProps]);
  return (
    <>
      {/* <Providers
        name={`${name}.${FiltersFields.providers}`}
        onChange={onSubmit}
      /> */}
      <Offers name={`${name}.${FiltersFields.offers}`} title="Offers" onChange={onSubmit} canShowBorder={false} />
    </>
  );
});