import cn from 'classnames';
import { Box } from 'uikit/Box';
import { Card } from 'uikit/Card';
import { Theme } from 'uikit/common/types';
import { CardTemplateDataProps } from './types';
import classes from '../CardTemplate.module.scss';

export const CardTemplateData = ({
  card, onClick, theme = Theme.dark, testId,
}: CardTemplateDataProps) => (
  <Card {...{ onClick, theme, testId }}>
    <Box className={cn(classes.card, { [classes.disabled]: card.disabled })} direction="column">
      <div className={classes.groupLine}>
        <div className={classes.headerLine}>{card.name}</div>
        {card.button}
      </div>
      <Box alignItems="center" justifyContent="space-between" className={classes.group}>
        <div>
          <span className={classes.title}>Provider:</span>
          <span className={classes.text}>{card.provider}</span>
        </div>
        <div>
          <span className={classes.title}>Offer ID:</span>
          <span className={classes.text}>{card.id}</span>
        </div>
      </Box>
      <div className={classes.footerText}>{card.description}</div>
    </Box>
  </Card>
);
