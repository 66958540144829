import { memo, FC } from 'react';
import { Box } from 'uikit/Box';
import { FiltersFilterItemProps } from './types';

export const FiltersFilterItem: FC<FiltersFilterItemProps> = memo(({ children, label, className }) => {
  return (
    <Box direction="row" justifyContent="space-between" alignItems="center" className={className}>
      {!!label && <Box>{label}</Box>}
      {children}
    </Box>
  );
});