import { memo, FC, useCallback } from 'react';
import { useRouter } from 'next/router';
import { Box } from 'uikit/Box';
import { Icon } from 'uikit/Icon';
import { Button } from 'uikit/Buttons/Button';
import { Theme } from 'uikit/common/types';
import { NotificationLineReplenishOrdersProps } from './types';
import classes from './NotificationLineReplenishOrders.module.scss';

export const NotificationLineReplenishOrders: FC<NotificationLineReplenishOrdersProps> = memo(({
  count = 0,
  theme = Theme.dark,
}) => {
  const { push } = useRouter();

  const onClick = useCallback(() => {
    push('/all-orders');
  }, [push]);

  return (
    <Box alignItems="center" className={classes[theme]}>
      <Icon name={theme === Theme.dark ? 'info' : 'info_light'} width={11} className={classes.icon} />
      <span>You have {count} orders that are running out of funds. Please replenish them</span>
      <Button className={classes.btn} variant="orange" onClick={onClick}>Go to orders</Button>
    </Box>
  );
});