import { forwardRef } from 'react';
import cn from 'classnames';
import { Theme } from 'uikit/common/types';
import { Icon } from 'uikit/Icon';
import { PagerBtnProps } from './types';
import { Button } from '../../Buttons/Button';
import classes from './PagerBtn.module.scss';

export const PagerBtn = forwardRef<HTMLButtonElement, PagerBtnProps>(({
  className,
  theme = Theme.dark,
  icon,
  ...props
}, ref) => {
  return (
    <Button
      ref={ref}
      theme={theme}
      variant="transparent"
      className={cn(
        classes[theme],
        classes.root,
        className,
      )}
      {...props}
    >
      <Icon className={classes.icon} name={icon} width={10} height={10} />
    </Button>
  );
});
