import { memo, FC } from 'react';
import Link from 'next/link';
import classes from './UnknownError.module.scss';
import { UnknownErrorProps } from './types';

export const UnknownError: FC<UnknownErrorProps> = memo(() => {
  return (
    <span>
      There was an error during validation of the order.
      Please contact us at&nbsp;
      <Link
        className={classes.link}
        rel="noopener noreferrer"
        target="_blank"
        href="https://discord.com/invite/superprotocol"
      >
        Discord
      </Link>
      .
    </span>
  );
});