import { Box } from 'uikit/Box';
import { OfferSelectedLineProps } from './types';
import classes from './OfferSelectedLine.module.scss';

export const OfferSelectedLine = ({
  label, value, testId, className,
}: OfferSelectedLineProps) => {
  return (
    <Box data-test-id={testId} className={className}>
      {!!label && <span className={classes.label}>{label}:</span>}
      <span className={classes.value}>{value}</span>
    </Box>
  );
};
