import { memo, FC } from 'react';
import cn from 'classnames';
import { Box } from 'uikit/Box';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { Header } from '../Header';
import classes from './Wrap.module.scss';
import { WrapProps } from './types';

export const Wrap: FC<WrapProps> = memo(({ children, className }) => {
  const theme = useAppSelector(themeSelector);
  return (
    <Box className={cn(classes.wrap, classes[theme], className)} direction="column" justifyContent="space-between">
      <Header className={classes.header} />
      {children}
    </Box>
  );
});