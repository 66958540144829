import cn from 'classnames';
import { Box } from 'uikit/Box';
import { Card } from 'uikit/Card';
import { Divider } from 'uikit/Divider';
import { Theme } from 'uikit/common/types';
import { CardTemplateComputeProps } from './types';
import classes from '../CardTemplate.module.scss';

export const CardTemplateCompute = ({
  card, onClick, theme = Theme.dark, testId,
}: CardTemplateComputeProps) => (
  <Card {...{ onClick, theme, testId }}>
    <Box className={cn(classes.card, { [classes.disabled]: card.disabled })} direction="column">
      <div className={classes.groupLine}>
        <div className={classes.headerLine}>{card.name}</div>
        {card.button}
      </div>
      <Box alignItems="center" justifyContent="space-between" className={classes.group}>
        <div>
          <span className={classes.title}>Provider:</span>
              &nbsp;
          <span className={classes.provider}>{card.provider}</span>
        </div>
      </Box>
      {!!card.properties && (
        <>
          <Divider />
          <Box className={classes.propsLine} wrap="wrap" justifyContent="space-between">
            {Object.entries(card.properties).map(([key, value]) => (
              <div key={key} className={classes.prop}>
                <span className={classes.title}>{`${key}:`}</span>
                &nbsp;
                <span className={classes.value}>{value}</span>
              </div>
            ))}
          </Box>
        </>
      )}
    </Box>
  </Card>
);
