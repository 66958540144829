import { BuildOrderForm } from 'lib/features/createOrder/types';
import { getFixedDeposit } from 'common/utils/sdk';
import { FormattedString } from './types';

export const formatByStructure = (core: string, content) => (
  `${core} ${content.value},${content.slots.slot.id}`
);

export const getFormattedString = (data: BuildOrderForm, lease?: number): FormattedString => {
  const init = {
    solution: (content) => {
      const core = '--solution';
      const result = formatByStructure(core, content);
      let base = '';
      if (content?.base?.length) {
        const contentBase = content.base[0];
        base = formatByStructure(core, contentBase);
      }
      const textBase = base ? ` ${base}` : '';
      return `${result}${textBase}`;
    },
    data: (content) => {
      const core = '--data';
      return content.map((item) => formatByStructure(core, item)).join(' ');
    },
    file: (content) => {
      const core = '--data';
      return `${core} ./${content}`;
    },
    tee: (content) => {
      const resultTee = formatByStructure('--tee', content);
      let resultOptions = '';
      if (content?.slots?.options?.length) {
        const { id, count } = content.slots.options[0];
        resultOptions = ` --tee-options ${id} --tee-options-count ${count}`;
      }
      return `${resultTee} --tee-slot-count ${content.slots.slot.count}${resultOptions}`;
    },
    storage: (content) => {
      return formatByStructure('--storage', content);
    },
  };
  const { deposit } = data;
  let fixedDeposit = '';
  if (deposit) {
    fixedDeposit = ` --deposit ${getFixedDeposit({
      deposit,
      count: 18,
    })}`;
  }
  let formatLease = '';
  if (lease) {
    formatLease = ` --min-rent-minutes ${lease * 60}`;
  }
  const result = Object.entries(init).reduce((acc, [k, v]) => {
    const content = data[k];
    return content ? `${acc} ${v(content)}` : acc;
  }, '').trim();
  return {
    isComplete: !!result,
    str: `${result}${fixedDeposit}${formatLease}`,
  };
};

export const href = 'https://docs.superprotocol.com/developers/cli_commands/workflows/create/';
