import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/hooks';
import { useAddOfferToForm } from 'lib/features/createOrder/hooks/useAddOfferToForm';
import { addErrorNotification, addSuccessNotification } from 'lib/features/notifications';
import { FieldsBuildOrderForm } from 'lib/features/createOrder/types';
import { TeeOffersAndSLots, OfferConfiguration } from 'generated/types';
import { useExtendedBuildOrderForm } from 'lib/features/createOrder/hooks/useExtendedBuildOrderForm';
import { OffersPages } from 'common/types/pages';
import { buildOrderFormSelector } from 'lib/features/createOrder/selectors';
import { useLazyGetMinimalConfigurationQuery } from 'generated/schemas/teeOffers';
import { getFiltersFromMinimalConfiguration, getPreparedForm } from 'lib/features/filters/helpers';
import { updatePageFilters } from 'lib/features/filters';
import {
  getOffersWithSlotsIds,
  getPreparedSlots,
} from 'lib/features/createOrder/helpers';
import { useLazyGetMatchingTeeSlotsQuery } from 'lib/features/teeOffers';
import { Sorting } from 'components/Main/Content/ControlPanel/Sorting/types';
import { FormFiltersCompute, FiltersFields } from 'lib/features/filters/types';
import { pageFiltersSelector } from 'lib/features/filters/selectors';

interface UpdateLeasProps {
  lease?: number; // current lease in filter
  filters?: FormFiltersCompute; // current filters in compute tab
  configuration?: TeeOffersAndSLots; // new auto selected configuration
  minimalConfiguration?: OfferConfiguration; // minimal configuration by offers and slots
}

export const useAutoSelectTEE = () => {
  const currentComputeFilters = useAppSelector(pageFiltersSelector(OffersPages.compute));
  const dispatch = useAppDispatch();
  const [fetchMatchinTeeSlots] = useLazyGetMatchingTeeSlotsQuery();
  const [getMinimalConfiguration] = useLazyGetMinimalConfigurationQuery();
  const buildOrderForm = useAppSelector(buildOrderFormSelector);
  const { loading: loadingExtending, extendedBuildOrderForm } = useExtendedBuildOrderForm(buildOrderForm);
  const [loadingAutoSelect, setLoadingAutoSelect] = useState(false);
  const { addOfferToForm, attentionModal, loading: loadingAddToForm } = useAddOfferToForm();

  const showError = useCallback((error: Error) => {
    dispatch(addErrorNotification(error?.message || 'Error'));
  }, [dispatch]);

  const showSuccess = useCallback((message: string) => {
    dispatch(addSuccessNotification(message));
  }, [dispatch]);

  const prepareVariables = useCallback(async (filters: FormFiltersCompute) => {
    return getPreparedForm({
      filters,
      controlPanel: { size: 1, sorting: Sorting.availability },
    }, extendedBuildOrderForm, OffersPages.compute);
  }, [extendedBuildOrderForm]);

  const getFirstMatchingTeeConfiguration = useCallback(async () => {
    const minimalConfigurationResponse = await getMinimalConfiguration({ offers: getOffersWithSlotsIds(buildOrderForm) });
    const minimalConfiguration = minimalConfigurationResponse?.data?.result;
    const filters = getFiltersFromMinimalConfiguration(minimalConfiguration);
    const variables = await prepareVariables(filters);
    const matchinTeeSlots = await fetchMatchinTeeSlots(variables);
    const configuration = matchinTeeSlots?.data?.result?.page?.edges?.[0]?.node as TeeOffersAndSLots;
    if (!configuration) throw new Error('Configuration not found');
    return { configuration, filters, minimalConfiguration };
  }, [fetchMatchinTeeSlots, prepareVariables, buildOrderForm, getMinimalConfiguration]);

  const updateConfiguration = useCallback(async (firstMathingTEEConfiguration: TeeOffersAndSLots) => {
    const { teeOffer } = firstMathingTEEConfiguration;
    const { id } = teeOffer || {};
    await addOfferToForm({
      value: id,
      field: FieldsBuildOrderForm.tee,
      data: teeOffer,
      slots: getPreparedSlots(firstMathingTEEConfiguration),
      skipConflict: true,
    });
  }, [addOfferToForm]);

  const updateLease = useCallback((props: UpdateLeasProps) => {
    const {
      configuration, minimalConfiguration, filters,
    } = props;
    const MINUTES_IN_HOURS = 60;
    const currentLease = currentComputeFilters?.filters?.[FiltersFields.lease]?.time;
    // get max minTimeMinutes. From current filter lease (hours), offers slots and fetched configuration
    const checkedLease = Math.max(
      (currentLease || 0),
      Math.ceil((configuration?.slotResult?.slot?.usage?.minTimeMinutes ?? 0) / MINUTES_IN_HOURS),
      Math.ceil((minimalConfiguration?.minTimeMinutes ?? 0) / MINUTES_IN_HOURS),
    );
    // save new filters to store
    dispatch(
      updatePageFilters({
        page: OffersPages.compute,
        filters: {
          ...currentComputeFilters, // save old control panel
          filters: { ...filters, [FiltersFields.lease]: { time: checkedLease } }, // update filters by new lease
        },
      }),
    );
  }, [dispatch, currentComputeFilters]);

  const autoSelectTEE = useCallback(async () => {
    try {
      setLoadingAutoSelect(true);
      const { configuration, filters, minimalConfiguration } = await getFirstMatchingTeeConfiguration();
      await updateConfiguration(configuration);
      updateLease({
        filters, configuration, minimalConfiguration,
      });
      showSuccess('Configuration has been updated');
    } catch (e) {
      showError(e as Error);
    } finally {
      setLoadingAutoSelect(false);
    }
  }, [
    showError,
    getFirstMatchingTeeConfiguration,
    updateConfiguration,
    showSuccess,
    updateLease,
  ]);

  return {
    attentionModal,
    loading: loadingAutoSelect || loadingAddToForm || loadingExtending,
    autoSelectTEE,
  };
};