import { memo } from 'react';
import dynamic from 'next/dynamic';
import { useAppSelector } from 'lib/hooks';
import { isMobileSelector } from 'lib/features/windowSize';

const OfferModal = dynamic(() => import('../OfferModal/OfferModal'), { ssr: false });
const OfferMobile = dynamic(() => import('../MobileView/Offer/Offer'), { ssr: false });

export const OfferModalOrMobile = memo(() => {
  const isMobile = useAppSelector(isMobileSelector);

  if (isMobile) return <OfferMobile />;
  return <OfferModal />;
});