import {
  memo, FC, useMemo, useCallback,
} from 'react';
import cn from 'classnames';
import { Box } from 'uikit/Box';
import { Tabs } from 'uikit/Tabs';
import { TabsDropdown } from 'uikit/Tabs/TabsDropdown';
import { OffersPages } from 'common/types/pages';
import { trackEvent } from 'lib/features/events/thunks';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { isMobileSelector } from 'lib/features/windowSize';
import { CreateOrder } from './CreateOrder';
import { TopProps } from './types';
import { getPages } from './helpers';
import classes from './Top.module.scss';

export const Top: FC<TopProps> = memo(({ active, className }) => {
  const isMobile = useAppSelector(isMobileSelector);
  const dispatch = useAppDispatch();
  const theme = useAppSelector(themeSelector);
  const pages = useMemo(() => getPages(), []);
  const onChange = useCallback((tab: OffersPages) => {
    dispatch(trackEvent({ eventType: 'tab_open', property: { tab } }));
  }, [dispatch]);

  return (
    <Box justifyContent="space-between" alignItems="center" className={cn(classes.wrap, className)}>
      <Tabs
        list={pages}
        active={active}
        onClick={onChange}
        theme={theme}
        classNameItem={classes.item}
        classNameTabItem={classes.tabItem}
        classNameWrap={classes.tabsWrap}
      />
      <TabsDropdown
        list={pages}
        active={active}
        theme={theme}
        className={classes.dropdown}
        classNameDropdownToggle={classes.dropdownToggle}
        classNameDropdownMenu={classes.dropdownMenu}
      />
      {!isMobile && <CreateOrder />}
    </Box>
  );
});