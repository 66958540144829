import { memo, FC } from 'react';
import cn from 'classnames';
import { Box } from 'uikit/Box';
import { Theme } from 'uikit/common/types';
import { StepperProps } from './types';
import { Step } from './Step';
import classes from './Stepper.module.scss';

export const Stepper: FC<StepperProps> = memo(({
  list, className, full, theme = Theme.dark,
}) => {
  if (!list?.length) return null;
  return (
    <Box direction="column" className={cn(classes.wrap, { [classes.full]: full }, className)}>
      {list.map(({ title, status }, index) => (
        <Step key={`${title}-${index}`} title={title} count={index + 1} theme={theme} status={status} />
      ))}
    </Box>
  );
});