import { memo, FC } from 'react';
import cn from 'classnames';

import { Theme } from 'uikit/common/types';
import { Box } from 'uikit/Box';
import { HtmlBox } from 'uikit/HtmlBox';
import { Icon } from 'uikit/Icon';
import classes from './NotificationLine.module.scss';
import { NotificationLineProps } from './types';

export const NotificationLine: FC<NotificationLineProps> = memo(({
  onClick, children, onClose, theme = Theme.dark, mode = 'default', dataTestId, classNameWrap, left, classNameChildren,
}) => {
  return (
    <Box
      className={cn(classes.content, classes[mode], classes[theme], classNameWrap)}
      alignItems="center"
      justifyContent="space-between"
      onClick={onClick}
      data-testid={dataTestId}
    >
      {left}
      {
        typeof children === 'string'
          ? <HtmlBox className={cn(classes.childrenHtml, classes[theme], classNameChildren)} text={children} theme={theme} />
          : <div className={cn(classes.children, classNameChildren)}>{children}</div>
      }
      {!!onClose && <Icon name="close_small" width={9} className={classes.icon} onClick={onClose} />}
    </Box>
  );
});
