import { memo, FC } from 'react';
import cn from 'classnames';
import { Box } from 'uikit/Box';
import { Notification } from 'uikit/Notification';
import { getErrorTransactionsTemplate } from 'common/utils';
import { Theme } from 'uikit/common/types';
import { ProcessItemProps } from './types';
import { ProcessStatus } from '../ProcessStatus';
import classes from './ProcessItem.module.scss';
import { Status } from '../ProcessStatus/types';

export const ProcessItem: FC<ProcessItemProps> = memo(({
  name,
  status,
  className,
  error,
  theme = Theme.dark,
}) => {
  if (!status || [Status.QUEUE_HIDE, Status.DONE_HIDE].includes(status)) return null;
  return (
    <Box className={cn(classes.wrap, className)} direction="column">
      <Box justifyContent="space-between" className={classes.item}>
        <Box className={classes.name}>{name}</Box>
        <ProcessStatus theme={theme} status={status} className={classes.processStatus} />
      </Box>
      {!!error && (
        <Notification
          theme={theme}
          className={classes.error}
          variant="error"
        >
          {getErrorTransactionsTemplate(error)}
        </Notification>
      )}
    </Box>
  );
});
