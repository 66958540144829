import { ExtPorts } from 'lib/features/filters/types';

export const getObjectCounter = (obj: object): number => Object
  .values(obj || {})
  .reduce((acc, value) => {
    let res = 1;
    if (typeof value === 'string' && value === ExtPorts.any) {
      res = 0;
    } else {
      res = value ? 1 : 0;
    }
    return acc + res;
  }, 0);