import { GetFieldsProps } from './types';
import { getCommonFields } from './common/fields';

export const getFields = ({
  buildOrderForm, loading, onAddFormOffer, fieldBuildOrderForm, onDeleteFormOffer,
}: GetFieldsProps) => [
  {
    id: 'id',
    cell: (row) => {
      const { id } = row || {};
      return id;
    },
  },
  {
    id: 'name',
    cell: (row) => {
      const { name } = row.offerInfo || {};
      return name;
    },
  },
  {
    id: 'provider',
    cell: (row) => {
      const { name } = row.providerInfo || {};
      return name;
    },
  },
  ...getCommonFields({
    buildOrderForm, loading, onAddFormOffer, fieldBuildOrderForm, onDeleteFormOffer,
  }),
];
