import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/hooks';
import {
  ProcessStatus, ProcessType, Process,
} from 'lib/features/createOrder/types';
import { getInitialProcess } from 'lib/features/createOrder/helpers';
import {
  updateProcesses as updateProcessesAction,
  updateProcess as updateProcessAction,
  updateSubmitLoading as updateSubmitLoadingAction,
  processSelector,
  submitLoadingSelector,
} from 'lib/features/createOrder';

export interface ChangeStateProps {
  process: ProcessType;
  status: ProcessStatus;
  error?: Error;
}

export interface UseWorkflowProcessResult {
  state: Process;
  progress: number;
  changeState: (props: ChangeStateProps) => void;
  init: (processList: ProcessType[]) => void;
  rerunNotDone: () => void;
  loading: boolean;
  onChangeLoading: (loading: boolean) => void;
}

export const useWorkflowProcess = (): UseWorkflowProcessResult => {
  const state = useAppSelector(processSelector);
  const loading = useAppSelector(submitLoadingSelector);
  const dispatch = useAppDispatch();
  const onChangeLoading = useCallback((loading: boolean) => {
    dispatch(updateSubmitLoadingAction(loading));
  }, [dispatch]);
  const updateState = useCallback((state: Process) => {
    dispatch(updateProcessesAction(state));
  }, [dispatch]);
  const changeState = useCallback((props: ChangeStateProps) => {
    const { process, status, error } = props;
    dispatch(updateProcessAction({ process, value: { status, error } }));
  }, [dispatch]);
  const rerunNotDone = useCallback(() => {
    updateState(
      Object.entries(state).reduce((acc, [process, values]) => {
        const processStatus = process === ProcessType.APPROVE ? ProcessStatus.QUEUE_HIDE : ProcessStatus.QUEUE;
        return ({
          ...acc,
          [process]: {
            ...values,
            error: undefined,
            status: ![ProcessStatus.DONE, ProcessStatus.DONE_HIDE].includes(values?.status)
              ? processStatus
              : values?.status,
          },
        });
      }, {}),
    );
  }, [state, updateState]);
  const progress = useMemo(() => {
    const entries = Object.entries(state);
    if (!entries.length) return 0;
    const doneStatuses = entries.filter(([, { status }]) => [ProcessStatus.DONE, ProcessStatus.DONE_HIDE].includes(status));
    return Math.floor((doneStatuses.length / entries.length) * 100);
  }, [state]);
  const init = useCallback((processList: ProcessType[]) => {
    updateState(getInitialProcess(processList));
  }, [updateState]);
  return {
    progress,
    state,
    changeState,
    init,
    rerunNotDone,
    loading,
    onChangeLoading,
  };
};
