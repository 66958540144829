import { useEffect, useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'lib/hooks';
import {
  addAlert, removeAlert, alertsChekerSelector, addAlertChecker,
} from 'lib/features/alerts';
import { isMobileSelector } from 'lib/features/windowSize';
import { Notifications } from 'lib/features/alerts/types';
import { ListAlert, Views } from './types';

export const useNotificationsLineAllerts = (listAlerts: ListAlert[]) => {
  const isMobile = useAppSelector(isMobileSelector);
  const alertsChecker = useAppSelector(alertsChekerSelector);
  const dispatch = useAppDispatch();
  const currentView = useMemo(() => (isMobile ? Views.mobile : Views.desktop), [isMobile]);
  const onClose = useCallback((id: Notifications) => {
    dispatch(addAlertChecker({ id }));
  }, [dispatch]);

  useEffect(() => {
    listAlerts.forEach(({
      view, id, text, mode,
    }) => {
      if (view.includes(currentView) && !alertsChecker[id]) {
        dispatch(addAlert({
          text,
          mode: mode ?? 'default',
          id,
        }));
      } else {
        dispatch(removeAlert({
          id,
        }));
      }
    });
  }, [dispatch, alertsChecker, listAlerts, currentView]);

  return { onClose };
};
