import {
  useCallback, useMemo, useState, SyntheticEvent,
} from 'react';
import cn from 'classnames';
import { NotificationLine } from 'uikit/NotificationLine';
import { Box } from 'uikit/Box';
import { Pager } from 'uikit/Pager';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { isMobileSelector } from 'lib/features/windowSize';
import { Alert, Notifications } from 'lib/features/alerts/types';
import classes from './NotificationsLine.module.scss';
import { useNotificationsLine } from './hooks/useNotificationsLine';
import { NotificationLineReplenishOrders } from './NotificationLineReplenishOrders';

export const NotificationsLine = () => {
  const theme = useAppSelector(themeSelector);
  const isMobile = useAppSelector(isMobileSelector);
  const {
    ordersCountAwaitingPayment, alerts, onClose: onCloseNotification,
  } = useNotificationsLine();
  const [currentIndex, setCurrentIndex] = useState(0);
  const onChangeCurrentIndex = useCallback((newCurrent: number) => {
    setCurrentIndex(newCurrent);
  }, []);
  const onClose = useCallback((e: SyntheticEvent, id: Notifications) => {
    setCurrentIndex(0);
    onCloseNotification(e, id);
  }, [onCloseNotification]);
  const pager = useMemo(
    () => (isMobile ? null : <Pager total={alerts.length} index={currentIndex} onChange={onChangeCurrentIndex} theme={theme} />),
    [alerts, currentIndex, onChangeCurrentIndex, theme, isMobile],
  );

  const renderNotification = useCallback((alert: Alert) => {
    const { id, text, mode } = alert || {};
    switch (id) {
      case Notifications.REPLENISH_ORDERS:
        return (
          <NotificationLine
            key={id}
            mode={mode}
            data-testid={`notification-line-${alert.mode}`}
            theme={theme}
            onClose={(e) => onClose(e, id as Notifications)}
            left={pager}
          >
            <NotificationLineReplenishOrders count={ordersCountAwaitingPayment} theme={theme} />
          </NotificationLine>
        );
      default:
        return (
          <NotificationLine
            key={id}
            mode={mode}
            data-testid={`notification-line-${alert.mode}`}
            onClose={(e) => onClose(e, id as Notifications)}
            theme={theme}
            classNameChildren={classes.lineChildrenBase}
            left={pager}
          >
            {text}
          </NotificationLine>
        );
    }
  }, [ordersCountAwaitingPayment, onClose, theme, pager]);

  return (
    alerts.length
      ? (
        <Box direction="column" className={cn(classes[theme], classes.lines)}>
          {renderNotification(alerts[currentIndex])}
        </Box>
      ) : <div className={classes.skel} />
  );
};
