import * as Yup from 'yup';
import { ColumnDef } from '@tanstack/react-table';
import { OffersPages } from 'common/types/pages';
import { ControlPanelFields } from 'components/Main/Content/ControlPanel/types';
import { OfferSlot, PriceType, TOfferType } from 'generated/types';
import { FieldsBuildOrderForm } from 'lib/features/createOrder/types';
import { Sorting } from 'components/Main/Content/ControlPanel/Sorting/types';
import {
  Availability, FiltersFields, Form, Blocks, FormControlPanel, FormFilters,
} from 'lib/features/filters/types';
import { GetColumnsProps } from './types';
import { getColumns as getColumnsSolution } from './columns/solution';
import { getColumns as getColumnsData } from './columns/data';
import { getColumns as getColumnsCompute } from './columns/compute';
import { getColumns as getColumnsStorage } from './columns/storage';
import { getFields as getFieldsSolution } from './fields/solution';
import { getFields as getFieldsStorage } from './fields/storage';
import { Column } from './Table/types';

export const INTERVAL = 300;

export const getValidationSchemaConrolPanel = (): Yup.SchemaOf<FormControlPanel> => Yup.object({
  [ControlPanelFields.sorting]: Yup.mixed().oneOf(Object.values(Sorting)).nullable().notRequired(),
  [ControlPanelFields.size]: Yup.number().nullable().notRequired(),
});

export const getValidationSchemaFilters = (): Yup.SchemaOf<FormFilters> => Yup.object({
  [FiltersFields.slots]: Yup.object({
    cpuCores: Yup.number().notRequired().nullable(),
    ram: Yup.number().notRequired().nullable(),
    diskUsage: Yup.number().notRequired().nullable(),
  }).notRequired(),
  [FiltersFields.availability]: Yup.mixed().oneOf(Object.values(Availability)).notRequired(),
  [FiltersFields.providers]: Yup.array().of(Yup.string().required()).notRequired().nullable(),
  [FiltersFields.compatible]: Yup.array().of(Yup.string().required()).notRequired().nullable(),
  [FiltersFields.offerType]: Yup.mixed<TOfferType>().oneOf(Object.values(TOfferType)).notRequired(),
  [FiltersFields.offers]: Yup.array().of(Yup.string().required()).notRequired().nullable(),
});

export const getValidationSchema = (): Yup.SchemaOf<Form> => Yup.object({
  [Blocks.filters]: getValidationSchemaFilters().nullable().notRequired(),
  [Blocks.controlPanel]: getValidationSchemaConrolPanel().nullable().notRequired(),
});

export function getColumns({
  active, ...rest
}: GetColumnsProps): ColumnDef<Column>[] {
  switch (active) {
    case OffersPages.solutions:
      return getColumnsSolution(rest);
    case OffersPages.data:
      return getColumnsData(rest);
    case OffersPages.compute:
      return getColumnsCompute(rest);
    case OffersPages.storage:
      return getColumnsStorage(rest);
    default:
      return [];
  }
}

const fieldsMap = {
  [OffersPages.solutions]: (props) => (
    getFieldsSolution({ ...props, fieldBuildOrderForm: FieldsBuildOrderForm.solution })
  ),
  [OffersPages.data]: (props) => (
    getFieldsSolution({ ...props, fieldBuildOrderForm: FieldsBuildOrderForm.data })
  ),
  [OffersPages.storage]: (props) => (
    getFieldsStorage({ ...props, fieldBuildOrderForm: FieldsBuildOrderForm.storage })
  ),
};

export const getFields = ({
  active, loading, onAddFormOffer, buildOrderForm, theme, onDeleteFormOffer,
}) => {
  return fieldsMap[active]?.({
    loading, onAddFormOffer, buildOrderForm, theme, onDeleteFormOffer,
  }) || null;
};

export const getBaseOffers = (data): string[] => {
  const ids: string[] = data.map((item) => (item?.offerInfo?.restrictions?.offers || [])).flat();
  return [...new Set(ids)];
};

export const checkIsDifferentOfferPriceType = (slots?: OfferSlot[]): boolean => {
  if (!slots || !slots.length) return true;
  const res = slots.reduce((acc: number, { usage }) => (acc + (usage.priceType === PriceType.Fixed ? 1 : 0)), 0);
  return !(res === 0 || res === slots.length);
};
