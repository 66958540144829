import { memo } from 'react';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { Accordion } from 'uikit/Accordion';
import { RadioFormik } from 'uikit/Radio/RadioFormik';
import { items } from './helpers';
import { CompatibleProps } from './types';
import classes from './Compatible.module.scss';

export const Compatible = memo(({
  classNameAccordionOpen, name, title, onChange,
}: CompatibleProps) => {
  const theme = useAppSelector(themeSelector);

  return (
    <Accordion
      title={title}
      classNameOpen={classNameAccordionOpen}
      classNameCollapse={classes.accordionCollapse}
      classNameToggleWrap={classes.accordionToggleWrap}
      defaultOpen
      theme={theme}
      dataTestId="compatible"
    >
      {items.map(({ value, label }) => (
        <RadioFormik
          key={value}
          name={name}
          value={value}
          label={label}
          classNameContainer={classes.container}
          theme={theme}
          onChange={onChange}
        />
      ))}
      <div className={classes.spacer} />
    </Accordion>
  );
});