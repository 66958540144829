import React, {
  memo, FC, useCallback, useMemo, useContext, useRef, useEffect, useState,
} from 'react';
import { useRouter } from 'next/router';
import { useMount } from 'react-use';
import cn from 'classnames';
import { Button } from 'uikit/Buttons/Button';
import { Box } from 'uikit/Box';
import { Notification } from 'uikit/Notification';
import { ProgressBar } from 'uikit/ProgressBar';
import { useAppDispatch, useAppSelector } from 'lib/hooks';
import { selectedAddressSelector } from 'lib/features/wallet';
import { FilesContext } from 'common/contexts/Files';
import { formSelector, reset as resetAction } from 'lib/features/createOrder';
import { addErrorNotification } from 'lib/features/notifications';
import { Steps } from 'lib/features/createOrder/types';
import { themeSelector } from 'lib/features/theme';
import { trackEvent } from 'lib/features/events/thunks';
import { getAnalyticsOffersFromFormOffers } from 'common/utils/analytics';
import { getErrorMessage } from 'common/utils/sdk';
import useWeb3Provider from 'common/hooks/useWeb3Provider';
import { ConfirmTransactionsProps } from './types';
import { Wrap } from '../Wrap';
import classes from './ConfirmTransactions.module.scss';
import {
  useWorkflow, Process, ProcessType, ProcessStatus,
} from './hooks/useWorkflow';
import { ProcessItem } from './ProcessItem';
import { useWorkflowProcess } from './hooks/useWorkflowProcess';
import { Status } from './ProcessStatus/types';
import { useCheckOrdersLimit } from '../hooks/useCheckOrdersLimit';
import { TIMEOUT } from './helpers';

export const ConfirmTransactions: FC<ConfirmTransactionsProps> = memo(() => {
  const { getWeb3Instance } = useWeb3Provider();
  const { loading: loadingCheck, check } = useCheckOrdersLimit();
  const timeout = useRef<ReturnType<typeof setTimeout> | undefined>();
  const theme = useAppSelector(themeSelector);
  const { files, reset } = useContext(FilesContext);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const selectedAddress = useAppSelector(selectedAddressSelector);
  const form = useAppSelector(formSelector);
  const [loadingBeforeRedirect, setLoadingBeforeRedirect] = useState(false);
  const {
    progress,
    runWorkflow,
    stateProcess,
    workflowId,
  } = useWorkflow(selectedAddress, useWorkflowProcess);
  const {
    tee,
    file,
    solution,
    data,
    storage,
  } = useMemo(() => form?.[Steps.BUILD_ORDER] || {}, [form]);
  const analyticsOffers = useMemo(() => getAnalyticsOffersFromFormOffers({
    solution,
    data,
    storage,
    tee,
  }), [tee, data, solution, storage]);
  const executeWorkflow = useCallback(async (state?: Process) => {
    if (!await check()) return;
    try {
      setLoadingBeforeRedirect(true);
      await runWorkflow({
        form,
        web3: await getWeb3Instance(),
        state,
        files,
      });
    } catch (e) {
      console.warn(e);
      dispatch(addErrorNotification(getErrorMessage(e as Error) || 'Workflow error'));
      setLoadingBeforeRedirect(false);
    }
  }, [form, runWorkflow, files, dispatch, check, getWeb3Instance]);

  useMount(() => {
    if (Object.values(stateProcess).every(({ status }) => status === ProcessStatus.QUEUE)) {
      executeWorkflow();
    }
  });

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  // wait until sdk workfow completes successfully and when orderId from backend is available
  useEffect(() => {
    if (workflowId && stateProcess.WORKFLOW?.status === ProcessStatus.DONE) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        router.push(`order/${workflowId}`).then(() => {
          dispatch(trackEvent({
            eventType: 'order_created', property: { order: { orderId: workflowId, offers: analyticsOffers } },
          }));
          dispatch(resetAction());
          reset();
        });
      }, TIMEOUT);
    }
  }, [workflowId, stateProcess.WORKFLOW, reset, dispatch, router, analyticsOffers]);

  return (
    <Wrap>
      <Box direction="column" className={cn(classes.content, classes[theme])}>
        <Box className={classes.title}>
          Your order and sub-orders are now being created on blockchain.
          You will be prompted by the Metamask app to confirm transactions.
        </Box>
        <ProgressBar theme={theme} progress={progress} className={classes.mrb} />
        <Notification
          theme={theme}
          className={classes.mrb}
          variant="warning"
        >Please do not close this window while it’s processing. You might lose you order.
        </Notification>
        {!!file && (
          <ProcessItem
            theme={theme}
            name="Upload file"
            status={stateProcess[ProcessType.FILE]?.status as unknown as Status}
            error={stateProcess[ProcessType.FILE]?.error}
          />
        )}
        {!!tee && (
          <>
            <ProcessItem
              theme={theme}
              name="Give permission to access your TEE"
              status={stateProcess[ProcessType.APPROVE]?.status as unknown as Status}
              error={stateProcess[ProcessType.APPROVE]?.error}
            />
            <ProcessItem
              theme={theme}
              name="Confirm transaction for order creation"
              status={stateProcess[ProcessType.WORKFLOW]?.status as unknown as Status}
              error={stateProcess[ProcessType.WORKFLOW]?.error}
            />
          </>
        )}
      </Box>
      <Box direction="column" className={classes.footer}>
        {!loadingBeforeRedirect && (
          <Box justifyContent="flex-end" className={classes.btns}>
            <Button
              theme={theme}
              loading={loadingCheck}
              onClick={() => executeWorkflow(stateProcess)}
              data-testid="button-try-again"
            >
              Try again
            </Button>
          </Box>
        )}
      </Box>
    </Wrap>
  );
});
