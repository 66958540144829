import { memo, FC } from 'react';
import cn from 'classnames';
import { Box } from 'uikit/Box';
import { CopyToClipboard } from 'uikit/Buttons/CopyToClipboard';
import { Button } from 'uikit/Buttons/Button';
import { Icon } from 'uikit/Icon';
import { Theme } from 'uikit/common/types';
import { PhraseGeneratorProps } from './types';
import classes from './PhraseGenerator.module.scss';

export const PhraseGenerator: FC<PhraseGeneratorProps> = memo(({
  phrase, onGenerate, theme = Theme.dark, onCopy,
}) => {
  return (
    <Box className={cn(classes.wrap, classes[theme])}>
      <Box className={classes.phrase}>{phrase}</Box>
      <Box className={classes.panel} direction="column" justifyContent="space-between">
        <Button variant={theme === Theme.dark ? 'black' : 'white'} onClick={onGenerate} className={classes.btnGenerate}>
          <Icon
            width={12}
            name="circle"
            className={classes.icon}
          />
        </Button>
        <CopyToClipboard
          className={classes.clipboard}
          theme={theme}
          text={phrase}
          onCopy={onCopy}
        />
      </Box>
    </Box>
  );
});