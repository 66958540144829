import { ExtPorts } from 'lib/features/filters/types';

export const externalPorts = [
  {
    value: ExtPorts.any,
    label: 'Any',
  },
  {
    value: ExtPorts.yes,
    label: 'Yes',
  },
];
