import { Item } from 'uikit/Tabs/types';
import { OffersPages } from 'common/types/pages';
import { pages } from '../helpers';

export const getPageLabel = (page: OffersPages): string => (pages[page] || '');

export const getPages = (): Item<OffersPages>[] => {
  return Object.values(OffersPages).map((page: OffersPages) => ({
    label: getPageLabel(page), value: page, href: `/${page}`,
  }));
};