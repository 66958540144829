import { memo } from 'react';
import { Header } from 'components/Header';
import { Main } from 'components/Main';
import { Box } from 'uikit/Box';
import { Node } from 'common/types/pages';
import { HomeProps } from './types';

export const Home = memo(({ page, prefetch }: HomeProps<Node>) => {
  return (
    <Box direction="column">
      <Header />
      <Main {...{ page, prefetch }} />
    </Box>
  );
});
