import { memo, FC, useCallback } from 'react';

import classes from './ConfigurationErrorAutoSelect.module.scss';
import { ConfigurationErrorAutoSelectProps } from './types';

export const ConfigurationErrorAutoSelect: FC<ConfigurationErrorAutoSelectProps> = memo(({ onAutoSelect }) => {
  const handlerOnKeyDown = useCallback(() => {}, []);

  return (
    <span>
      The selected solution/data requirements exceed selected compute configuration.
      You can use{/*
      */}
      <button
        className={classes.link}
        onClick={onAutoSelect}
        onKeyDown={handlerOnKeyDown}
      >
        auto-select
      </button>{/*
      */}to update configuration.
    </span>
  );
});