import { forwardRef, memo } from 'react';
import cn from 'classnames';
import { Theme } from 'uikit/common/types';
import { Paper } from 'uikit/Paper';
import { CardProps } from './types';
import classes from './Card.module.scss';

export const Card = memo(forwardRef<HTMLDivElement, CardProps>(({
  className,
  children,
  onClick,
  theme = Theme.dark,
  testId,
}, ref) => {
  return (
    <Paper ref={ref} className={cn(classes.content, className)} theme={theme} onClick={onClick} testId={testId}>
      {children}
    </Paper>
  );
}));
