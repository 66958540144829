/* eslint-disable max-len */
import { Notifications } from 'lib/features/alerts/types';
import { Views, ListAlert } from './types';

export const listAlerts: ListAlert[] = [
  {
    id: Notifications.CHANGE_NODE,
    text: `<span>Polygon has shut down the Mumbai testnet. We have moved to the new Polygon Amoy testnet. All orders have been reset.</span>
    <span>Metamask should update settings automatically. To update manually please refer&nbsp;<a href="https://docs.superprotocol.com/developers/marketplace/first-steps" target="_blank" rel="noopener noreferrer">here</a>&nbsp;
    for Marketplace and&nbsp;<a href="https://docs.superprotocol.com/developers/cli_guides/configuring" target="_blank" rel="noopener noreferrer">here</a>&nbsp;for SPCTL</span>`,
    view: [Views.desktop],
  },
  {
    id: Notifications.USE_DESKTOP,
    text: 'Mobile version of Marketplace is view-only. Please use desktop for full functionality.',
    view: [Views.mobile],
  },
];
