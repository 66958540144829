import {
  memo, FC, useState, useCallback,
} from 'react';
import dynamic from 'next/dynamic';
import { Button } from 'uikit/Buttons/Button';
import { Box } from 'uikit/Box';
import { trackEvent } from 'lib/features/events/thunks';
import { themeSelector } from 'lib/features/theme';
import { formBuildOrdersCountSelector } from 'lib/features/createOrder/selectors';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { CreateOrderModal } from './CreateOrderModal';
import { CreateOrderProps } from './types';
import classes from './CreateOrder.module.scss';

const BasketBtn = dynamic(() => import('../../../BasketBtn/BasketBtn'), { ssr: false });

export const CreateOrder: FC<CreateOrderProps> = memo(() => {
  const theme = useAppSelector(themeSelector);
  const dispatch = useAppDispatch();
  const count = useAppSelector(formBuildOrdersCountSelector);
  const [show, setShow] = useState(false);

  const showModal = useCallback(async () => {
    setShow(true);
    dispatch(trackEvent({ eventType: 'order_form_open' }));
  }, [dispatch]);

  const closeModal = useCallback(async () => {
    setShow(false);
    dispatch(trackEvent({ eventType: 'order_form_close' }));
  }, [dispatch]);

  return (
    <>
      <Box alignItems="center">
        <Button variant="base-link" href="/all-orders" theme={theme} className={classes.btnAll} data-testid="button-allorders">
          All orders
        </Button>
        <BasketBtn onClick={showModal} counter={count} data-testid="button-cart" />
      </Box>
      <CreateOrderModal show={show} onClose={closeModal} />
    </>
  );
});